<div class="main-container">
  <div class="subContainer">
    <div class="close-button-container">
      <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
    </div>

    <div>
      <p class="myParagraph">
        Regain Access: Answer your security questions to recover your account.
      </p>
    </div>
    <!-- <div> -->
    <!-- questions.component.html -->

    <div class="container">
      <div class="question">
        <label for="question1" class="myQtn">1.What was the name of your first pet?</label>
        <mat-form-field appearance="outline" class="input">
          <input
            matInput
            type="text"
            id="question1"
            [(ngModel)]="answers.question1"
          />
        </mat-form-field>
      </div>
      <div class="question">
        <label for="question2" class="myQtn">2.In which city were you born?</label>
        <mat-form-field appearance="outline" class="input">
          <input
            matInput
            type="text"
            id="question1"
            [(ngModel)]="answers.question2"
          />
        </mat-form-field>
      </div>
      <!-- Repeat the same structure for other questions -->

      <div style="margin-top: 30px">
        <button mat-button class="Confirm">Confirm</button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>
