import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss'],
})
export class ProfileCardComponent implements OnInit {
  @Input() component: string;
  @Input() data: any;
  @Output() messageEvent = new EventEmitter<string>();
  traderDetails: any;
  constructor(private _sanitizer: DomSanitizer) {}
  backgroundImageUrl: any;
  ngOnInit(): void {
    if (this.component == 'trader-area') {
      this.traderDetails = this.data[0];
      
    }
    if ('TotalTradePlaced' in this.traderDetails) {
    } else {
      Object.assign(this.traderDetails,{ TotalTradePlaced: 0 });
    }
    this.convertBase64();
  }

  convertBase64() {
    this.backgroundImageUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
      'data:image/jpg;base64,' + this.traderDetails.profilePic
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (
        this.component == 'copier-area' ||
        this.component == 'trader-profile'
      ) {
        this.traderDetails = changes.data.currentValue;
        if ('TotalTradePlaced' in this.traderDetails) {
        } else {
          Object.assign(this.traderDetails,{ TotalTradePlaced: 0 });
        }
        this.convertBase64();
      }
    }
  }

  sendMessage(message: string) {
    this.messageEvent.emit(message);
  }
}
