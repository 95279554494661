<div class="main-container">
  <div class="subContainer">

    <div class="close-button-container">
      <mat-icon mat-dialog-close style="cursor: pointer;">close</mat-icon>
    </div>

    <div>
      <p class="myParagraph">
        Secure Your Account: Set up personalized security questions to protect
        your platform, and answer to any two of them
      </p>
    </div>
    <!-- <div> -->
    <!-- questions.component.html -->

    <div class="container">
      <!-- <div class="question">
      <label for="question1">What was the name of your first pet?</label>
      <mat-form-field appearance="outline">
        <input matInput type="text" id="question1" [(ngModel)]="answers.question1">
      </mat-form-field>
     </div>
  
     <div class="question">
      <label for="question2">In which city were you born?</label>
      <mat-form-field appearance="outline">
        <input matInput type="text" id="question2" [(ngModel)]="answers.question2">
      </mat-form-field>
     </div>
  
     <div class="question">
      <label for="question3">What is the name of your favorite teacher?</label>
      <mat-form-field appearance="outline">
        <input matInput type="text" id="question3" [(ngModel)]="answers.question3">
      </mat-form-field>
     </div>
  
     <div class="question">
      <label for="question4">What is the name of your favorite vacation spot?</label>
      <mat-form-field appearance="outline">
        <input matInput type="text" id="question4" [(ngModel)]="answers.question4">
      </mat-form-field>
     </div> -->

      <form [formGroup]="SecurityQuestions">
        <div style="width: 85%">
          <div class="flexContent">
            <label for="question1" class="myLabel">Question 1</label
            >&nbsp;&nbsp;&nbsp;

            <mat-form-field class="input" appearance="outline">
              <mat-label >--Select question--</mat-label>
              <mat-select formControlName="question1" id="question1">
                <mat-option value={{question}} *ngFor="let question of secQuestionsList;let i = index" (click)="firstQuestion(i)" [disabled]="i === firstQuestionIndex"
                  >{{question}}</mat-option
                >
                <!-- <mat-option value="In which city were you born?"
                  >In which city were you born?</mat-option
                > -->
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flexContent">
            <label for="answer1" class="myLabel" style="color: #8E98A8 !important;">Your Answer</label
            >&nbsp;&nbsp;&nbsp;
            <mat-form-field appearance="outline" class="input">
              <input
                matInput
                type="text"
                id="answer1"
                formControlName="answer1"
              />
            </mat-form-field>
          </div>
          <div class="flexContent">
            <label for="question2" class="myLabel">Question 2</label
            >&nbsp;&nbsp;&nbsp;
            <mat-form-field class="input" appearance="outline" >
              <mat-label>--Select question--</mat-label>
              <mat-select formControlName="question2" id="question2" >
                <mat-option value={{question}} *ngFor="let question of secQuestionsList;let i = index" (click)="firstQuestion(i)" [disabled]="i === firstQuestionIndex"
                  >{{question}}</mat-option
                >
                <!-- <mat-option
                  value="What is the name of your favorite vacation spot?"
                  >What is the name of your favorite vacation spot?</mat-option
                > -->
              </mat-select>
            </mat-form-field>
          </div>
          <div class="flexContent">
            <label for="answer2" class="myLabel" style="color: #8E98A8 !important;">Your Answer</label
            >&nbsp;&nbsp;&nbsp;

            <mat-form-field appearance="outline" class="input">
              <input
                matInput
                type="text"
                id="answer2"
                formControlName="answer2"
              />
            </mat-form-field>
          </div>
        </div>
      </form>
      <div style="margin-top: 30px">
        <button mat-button class="saveBtn" (click)="saveQuestions(SecurityQuestions.value)" mat-dialog-close>Save Answers</button>
      </div>
    </div>
    <!-- </div> -->
  </div>
</div>
