<table>
  <tbody>
    <tr *ngIf="rowData">
      <td class="td-1"><span></span></td>
      <td class="td-2"><span></span></td>
      <td class="td-3"><span></span></td>
      <td class="td-4"></td>
      <td class="td-5"><span></span></td>
    </tr>
    <tr *ngIf="!rowData">
      <td class="td-1"><span></span></td>
      <td class="td-2"><span></span></td>
      <td class="td-3"><span></span></td>
      <td class="td-4"></td>
      <td class="td-5"><span></span></td>
    </tr>
    <tr *ngIf="!rowData">
      <td class="td-1"><span></span></td>
      <td class="td-2"><span></span></td>
      <td class="td-3"><span></span></td>
      <td class="td-4"></td>
      <td class="td-5"><span></span></td>
    </tr>
    <tr *ngIf="!rowData">
      <td class="td-1"><span></span></td>
      <td class="td-2"><span></span></td>
      <td class="td-3"><span></span></td>
      <td class="td-4"></td>
      <td class="td-5"><span></span></td>
    </tr>
    <tr *ngIf="!rowData">
      <td class="td-1"><span></span></td>
      <td class="td-2"><span></span></td>
      <td class="td-3"><span></span></td>
      <td class="td-4"></td>
      <td class="td-5"><span></span></td>
    </tr>
    <tr *ngIf="!rowData">
      <td class="td-1"><span></span></td>
      <td class="td-2"><span></span></td>
      <td class="td-3"><span></span></td>
      <td class="td-4"></td>
      <td class="td-5"><span></span></td>
    </tr>
    <tr *ngIf="!rowData">
      <td class="td-1"><span></span></td>
      <td class="td-2"><span></span></td>
      <td class="td-3"><span></span></td>
      <td class="td-4"></td>
      <td class="td-5"><span></span></td>
    </tr>
    <tr *ngIf="!rowData">
      <td class="td-1"><span></span></td>
      <td class="td-2"><span></span></td>
      <td class="td-3"><span></span></td>
      <td class="td-4"></td>
      <td class="td-5"><span></span></td>
    </tr>
    <tr *ngIf="!rowData">
      <td class="td-1"><span></span></td>
      <td class="td-2"><span></span></td>
      <td class="td-3"><span></span></td>
      <td class="td-4"></td>
      <td class="td-5"><span></span></td>
    </tr>
  </tbody>
</table>
