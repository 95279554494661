export class LocalStorageConstants {
  public static NEW_USER = 'NEW_USER';
  public static AUTH_USER = 'AUTH_USER';
  public static RESEND_OTP_KEY = 'RESEND_OTP_KEY';
  public static USER_PROFILE = 'USER_PROFILE';
  public static REGISTERED_USER = 'REGISTERED_USER';
  public static GAUTH_TOKEN = 'GAUTH_TOKEN';
  public static GAUTH_SECRET = 'GAUTH_SECRET';
  public static GAUTH_STATUS = 'GAUTH_STATUS';
}
