import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cc-card-loading',
  templateUrl: './card-loading.component.html',
  styleUrls: ['./card-loading.component.scss']
})
export class CardLoadingComponent implements OnInit {
  cards = [1,2,3,4,5,6]
  constructor() { }

  ngOnInit(): void {
  }

}
