import { NgModule } from '@angular/core';
import { DndDirective } from './dnd.directive';
import { OnlyNumbersDirective } from './only-numbers.directive';

@NgModule({
    declarations: [
        DndDirective,
        OnlyNumbersDirective,
    ],
    imports: [],
    exports:[DndDirective,OnlyNumbersDirective],
    providers: [],
    bootstrap: []
})
export class DirectiveModules { }
