import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEndPoints } from 'src/config/end-points.constants';
import { HeadersConstants } from 'src/config/headers.constants';

@Injectable({
  providedIn: 'root',
})
export class CopytraderService {
  constructor(public http: HttpClient) {}

  getAllTraders(userId: string) {
    let url = AppEndPoints.GETTRADERS + userId;
    return this.http.get(url, HeadersConstants.noTokenHeaders);
  }

  getTraderDetails(data: any) {
    let url =
      AppEndPoints.TraderDetails + data.userId + '&traderId=' ;
    return this.http.get(url, HeadersConstants.noTokenHeaders);
  }

  getTraderProfile(data: any,userId : any) {
    let url = AppEndPoints.TraderProfile + '&traderId=' + data.traderId + '&userId=' + userId;
    return this.http.get(url, HeadersConstants.noTokenHeaders);
  }

  enableCopyTrader(data: any) {
    let url = AppEndPoints.ENABLECOPYTRADER;
    return this.http.post(url, data);
  }

  // trader area
  getTradedPairs(exchange: string, userId: string) {
    let url =
      AppEndPoints.GET_USER_TRADED_PAIRS +
      'exchange=' +
      exchange +
      '&traderId=' +
      userId;
    return this.http.get(url, HeadersConstants.tockenHeader);
  }

  enablePair(data: any) {
    // let url = 'http://localhost:3102/api/v1.0/trading/copytrader/enable';
    let url = AppEndPoints.ENABLE_DISABLE_PAIR;
    return this.http.post(url, data, HeadersConstants.tockenHeader);
  }

  getUserTradedExchanges(userId: string) {
    let url = AppEndPoints.GET_USER_EXCHANGES + 'userId=' + userId;
    return this.http.get(url, HeadersConstants.noTokenHeaders);
  }

  // copyCat
  copyTrader(traderId: any, data: any) {
    let url = AppEndPoints.ENABLE_COPY_TRADER + '?traderId=' + traderId;
    return this.http.post(url, data);
  }

  // copier area copied traders
  copedTraders(userID: string) {
    let url = AppEndPoints.GET_ALL_COPIED_TRADERS + '?userId=' + userID;
    return this.http.get(url);
  }

  copiedPairs(traderId : any,userID: string){
    let url = AppEndPoints.GET_ALL_COPIED_PAIRS + 'traderId=' + traderId + '&userId=' + userID;
    return this.http.get(url);
  }

  addCoinPairs(traderId : any,data: any){
    let url = AppEndPoints.ADD_COINPAIRS + 'traderId=' + traderId ;
    return this.http.post(url, data);
  }

  unFollowTrader(traderId : any,data: any){
    let url = AppEndPoints.UNFOLLOW_TRADER + 'traderId=' + traderId ;
    return this.http.post(url, data);
  }
}
