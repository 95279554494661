<div class="buy_sell_modal">
  <cc-loader *ngIf="loader"></cc-loader>
  <span *ngIf="!loader" >
    <div *ngIf="data.direction == 'buy'">
      <p>
        <mat-toolbar class="buy-toolBar">
          <span style="font-size: 15px; line-height: 21px"
            >BUY {{ data.symbol.split("-")[0] }}</span
          >
          <span class="spacer"></span>
          <mat-slide-toggle
            [(ngModel)]="activateSell"
            (change)="changeToSell($event)"
            style="font-size: 15px; line-height: 21px; font-weight: 500;"
            >SELL</mat-slide-toggle
          >
        </mat-toolbar>
      </p>
      <form [formGroup]="buyorderForm">
        <mat-radio-group
          color="primary"
          class="radioGroup custom-radio-group"
          formControlName="market"
        >
          <mat-radio-button style="font-size: 16px; line-height: 24px" value="M"
            >Market</mat-radio-button
          >
          <mat-radio-button style="font-size: 16px; line-height: 24px" value="L"  [disabled]="disableLimitOrder"
            >Limit</mat-radio-button
          >
        </mat-radio-group>
        <div class="input-section">
          <mat-form-field appearance="outline"  class="custom-form-field" style="width: 90%">
            <input
              matInput
              formControlName="number_of_coins"
              (keypress)="numberOnly($event)"
              (input)="quantityChange($event)"
            />
            <span matSuffix class="suffix">{{
              data.symbol.split("-")[0]
            }}</span>
          </mat-form-field>

          <mat-form-field appearance="outline"  class="custom-form-field" style="width: 90%">
            <input matInput formControlName="amount" />
            <span matSuffix class="suffix">{{
              data.symbol.split("-")[1]
            }}</span>
          </mat-form-field>

          <p class="toggle">
            <mat-slide-toggle
              labelPosition="before"
              class="bktOrder"
              (change)="toggleChanged($event)"
              >Bucket Order</mat-slide-toggle
            >
          </p>
          <mat-form-field appearance="outline"  class="custom-form-field" style="width: 90%; margin-top: -10px;">
            <input
              matInput
              formControlName="bucketOrder"
              (input)="bucketChange($event)"
              [value]="bucketOrder || 1"
              [disabled]="!checked"
            />
          </mat-form-field>
          <div *ngIf="showBreakoutStrategy">
            <mat-slide-toggle style="float: right;" (change)="changebreakout($event)">Double Legged</mat-slide-toggle>
          </div>
          
        </div>
      </form>
      <div class="balance-view">
        <span
          style="
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            padding-left: 15px;
            color: #a0a2b1;
          "
          >Available balance</span
        >
        <span
          style="
            font-size: 10px;
            font-weight: 400;
            line-height: 15px;
            padding-right: 15px;
            color: #000;
          "
          >{{ balance }} {{ data.symbol.split("-")[1] }}</span
        >
      </div>
      <p *ngIf="showlowBalance">Low balance</p>
      <div class="reset">
        <button
          mat-raised-button
          class="cancel"
          (click)="onNoClick()"
          [disabled]="showlowBalance"
        >
          CANCEL
        </button>
        <!-- <mat-icon mat-dialog-close>cancel</mat-icon> -->
        <button
          mat-raised-button
          class="buy-button"
          (click)="placeTrade()"
          [disabled]="showlowBalance"
        >
          BUY
        </button>
      </div>
      <!-- <button
        mat-raised-button
        class="buy-button"
        (click)="placeTrade()"
        [disabled]="showlowBalance"
      >
        BUY
      </button> -->
    </div>

    <!-- sell section tradingdesk -->
    <div *ngIf="data.direction == 'sell'">
      <p>
        <mat-toolbar class="sell-toolBar">
          <span style="font-size: 15px; line-height: 21px"
            >SELL {{ data.symbol.split("-")[0] }}</span
          >
          <span class="spacer"></span>
          <mat-slide-toggle
            style="font-size: 15px; line-height: 21px; font-weight:500"
            [(ngModel)]="activateSell"
            labelPosition="before"
            (change)="changeToBuy($event)"
            >BUY</mat-slide-toggle
          >
        </mat-toolbar>
      </p>
      <form [formGroup]="buyorderForm" >
        <mat-radio-group
          color="primary"
          class="radioGroup  custom-radio-group"
          formControlName="market"
        >
          <mat-radio-button style="font-size: 16px; line-height: 24px" value="M"
            >Market</mat-radio-button
          >
          <mat-radio-button
            [disabled]="disableLimitOrder"
            style="font-size: 16px; line-height: 24px"
            value="L"
            >Limit</mat-radio-button
          >
        </mat-radio-group>
        <div class="input-section">
          <mat-form-field
            appearance="outline"
            class="custom-form-field"
            style="width: 90%;"
          >
            <input
              matInput
              formControlName="number_of_coins"
              (keypress)="numberOnly($event)"
              (input)="quantityChange($event)"
            />
            <span matSuffix class="suffix">{{
              data.symbol.split("-")[0]
            }}</span>
          </mat-form-field>

          <mat-form-field appearance="outline" class="custom-form-field" style="width: 90%">
            <input matInput formControlName="amount" />
            <span matSuffix class="suffix">{{
              data.symbol.split("-")[1]
            }}</span>
          </mat-form-field>

          <p class="toggle">
            <mat-slide-toggle
              labelPosition="before"
              class="bktOrder"
              [(ngModel)]="checked"
              (change)="toggleChanges($event)"
              >Bucket Order</mat-slide-toggle
            >
          </p>
          <mat-form-field appearance="outline" style="width: 90%; margin-top: -10px;">
            <input
              matInput
              formControlName="bucketOrder"
              (input)="bucketChange($event)"
              [value]="bucketOrder || 1"
              [disabled]="!checked"
            />
          </mat-form-field>
        </div>
      </form>
      <div class="balance-view">
        <span
          style="
            font-size: 10px;
            font-weight: 400;
            line-height: 25px;
            padding-left: 15px;
            color: #a0a2b1;
          "
          >Available balance</span
        >
        <span
          style="
            font-size: 10px;
            font-weight: 400;
            line-height: 25px;
            padding-right: 15px;
            color: #000;
          "
          >{{ balance }} {{ data.symbol.split("-")[0] }}</span
        >
      </div>

      <!-- <mat-slider
        thumbLabel
        [displayWith]="formatLabel"
        tickInterval="100"
        step="25"
        min="0"
        max="100"
        style="margin-top: -1%"
        (valueChange)="percentChange($event)"
      ></mat-slider> -->
      <p *ngIf="showlowBalance">Low balance</p>
      <div class="reset">
        <button
          mat-raised-button
          class="cancel"
          (click)="onNoClick()"
          [disabled]="showlowBalance"
        >
          CANCEL
        </button>
        <!-- <mat-icon mat-dialog-close>cancel</mat-icon> -->
        <button
          mat-raised-button
          class="sell-button"
          (click)="placeTrade()"
          [disabled]="showlowBalance"
        >
          SELL
        </button>
      </div>
      <!-- <button
      mat-raised-button
      class="sell-button"
      (click)="placeTrade()"
      [disabled]="showlowBalance"
    >
      Sell
    </button> -->
    </div>
  </span>
</div>
