import { HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { LocalStorageProvider } from 'src/providers/local-storage.provider';

export class HeadersConstants {
  private localStorage = new LocalStorageProvider();
  public static get noTokenHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    return httpOptions;
  }

  public static get noTokencustomer() {
    const httpOptions = {
      headers: new HttpHeaders({
        noToken: 'noToken',
        Role: 'customer',
      }),
    };
    return httpOptions;
  }

  public static get RoleCustomerHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        Role: 'customer',
        'Content-Type': ['application/json'],
      }),
    };
    return httpOptions;
  }

  public static get RoleTradeDummyCustomerHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        authToken: 'hjfjdsgfjugzadcbg',
        'Content-Type': ['application/json', 'text/plain'],
      }),
    };
    return httpOptions;
  }

  public static get tockenHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: localStorage.getItem('authUser'),
        role: 'customer',
      }),
    };
    return httpOptions;
  }

  public static get RoleTradeCustomerHeaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        authToken: 'hjfjdsgfjugzadcbg',
        'Content-Type': ['application/json', 'text/plain'],
      }),
    };
    return httpOptions;
  }

  public static get  exchangeHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        'X-RapidAPI-Key': 'fc29b3c6ebmsh286b17c7745efcbp159de8jsna21a683014fe',
        'X-RapidAPI-Host': 'currency-exchange.p.rapidapi.com'
      }),
    };
    return httpOptions;
  }

  public static get plainHeader() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': ['application/json', 'text/plain'],
      }),
    };
    return httpOptions;
  }

  public static get iifl() {
    const httpOptions = {
      headers: new HttpHeaders({
        noToken: 'noToken',
        'Content-Type': ['application/json', 'text/plain'],
        'Ocp-Apim-Subscription-Key': 'fc714d8e5b82438a93a95baa493ff45b',
      }),
    };
    return httpOptions;
  }

  public static get iifl2() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        noToken: 'noToken',
        'x-clientcode': '61978430',
        'x-auth-token':
          'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6IjYxOTc4NDMwIiwicm9sZSI6IjYwIiwibmJmIjoxNjk0NTc3MzQwLCJleHAiOjE2OTQ2MTMzNDAsImlhdCI6MTY5NDU3NzM0MH0.rFyqXpp0wjUFrSxjODdSmgHKIjRoK4sjHSdKgdFx4Eo',
        'Ocp-Apim-Subscription-Key': 'fc714d8e5b82438a93a95baa493ff45b',
      }),
    };
    return httpOptions;
  }
}
