import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-regain-questions',
  templateUrl: './regain-questions.component.html',
  styleUrls: ['./regain-questions.component.scss']
})
export class RegainQuestionsComponent implements OnInit {
  answers: any = {
    question1: '',
    question2: '',
    question3: '',
    question4: ''
  };
  constructor() { }

  ngOnInit(): void {
  }

}
