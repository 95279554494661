import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/providers/http/auth.service';
import { SharedService } from 'src/providers/http/shared.service';
import { SnackbarService } from 'src/providers/http/snack-bar.service';
import { LocalStorageProvider } from 'src/providers/local-storage.provider';
 
@Component({
  selector: 'site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent implements OnInit, AfterViewInit {
  darktheme = false;
  userId: any;
  loginUser: any;
  userDetails: any;
  imageUrl: any;
  profilePath: boolean;
  kycPath: boolean;
  fromProfilePath: boolean;
  apiPath: boolean;
  marketActive: boolean;
  constructor(
    private _auth: AuthService,
    public router: Router,
    private _storageProvider: LocalStorageProvider,
    private _sanitizer: DomSanitizer,
    private _shared: SharedService,
    private _snackbar: SnackbarService
  ) {
    this._shared.profileUpdate$.subscribe((res) => {
      this.imageUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
        'data:image/jpg;base64,' + res
      );
      this.loginUser = this._storageProvider.getLoggedInUserInfo();
      let data:any = JSON.parse(localStorage.getItem('AUTH_USER'));
      this.userId = data.userId;
      if(this.userId != ' '){
        this._auth.authenticateUser(this.userId).subscribe({
          next:(res:any) =>{
            if (res.data.userDetails.profile.pic == null) {
              this.imageUrl = './assets/imgs/logo.png';
            } else {
              this.imageUrl = this._sanitizer.bypassSecurityTrustResourceUrl(
                'data:image/jpg;base64,' + res.data.userDetails.profile.pic
              );
            }
          }
        })
      }
    });
  }
 
  ngOnInit(): void {
    this._shared.activePath$.subscribe((isActive: boolean) => {
      this.fromProfilePath = isActive;
      this.profilePath = this.fromProfilePath || this.kycPath || this.apiPath;
    });

    this._shared.activeProPath$.subscribe((isActive: boolean) => {
      this.kycPath = isActive;
      this.profilePath = this.fromProfilePath || this.kycPath || this.apiPath;
    });

    this._shared.activeApiPath$.subscribe((isActive: boolean) => {
      this.apiPath = isActive;
      this.profilePath = this.fromProfilePath || this.kycPath || this.apiPath;
    });
}

 
  myFunction(){
    
  }
  ngAfterViewInit() {
   
  }
 
  toggleDarkTheme(): void {
    this.darktheme = !this.darktheme;
    document.body.classList.toggle('dark-theme');
  }
 
  logout() {
    this._auth.logout();
  }
 
  toHome() {
    if (localStorage.getItem('authUser') != null) {
      this.router.navigate(['/home/' + this.userId]);
    } else if (localStorage.getItem('authUser') == null) {
      this.router.navigate(['/home']);
    }
  }
 
  marketOverview() {
    if (localStorage.getItem('authUser') != null) {
      this.router.navigate([
        '/market/' + this.userId + '/market-overview/',
      ]);
    } else if (localStorage.getItem('authUser') == null) {
      this.router.navigate(['/market/market-overview']);
    }
    this.marketActive = true;
  }
 
  market() {
    if (localStorage.getItem('authUser') != null) {
      this.router.navigate(['/market/' + this.userId]);
    } else if (localStorage.getItem('authUser') == null) {
      this._snackbar.info('Please Login for trading!!!');
      this.router.navigate(['/login']);
    }
    this.marketActive = true;
  }

  notActive(){
    this.marketActive = false;
  }
}