import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BannerComponent } from './banner/banner.component';
import { D3LineGraphComponent } from './d3-line-graph/d3-line-graph.component';
import { MaterialModule } from './materal.module';
import { SuccessMessageComponent } from './success-message/success-message.component';
import { TradingDeskModalComponent } from './trading-desk-modal/trading-desk-modal.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { BuysellModalComponent } from './buysell-modal/buysell-modal.component';
import { DataNotFoundComponent } from './data-not-found/data-not-found.component';
import { TableLoaderComponent } from './table-loader/table-loader.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardLoadingComponent } from './card-loading/card-loading.component';
import { TradersCardComponent } from './traders-card/traders-card.component';
import { TradingviewwidgetComponent } from './tradingviewwidget/tradingviewwidget.component';
import { TradingviewWidgetModule } from 'angular-tradingview-widget';
import { TradingviewwidgetStocksComponent } from './tradingviewwidgetstocks/tradingviewwidget.component';
import { HomePageComponent } from './home-page/home-page.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { SecurityQuestionsComponent } from './security-questions/security-questions.component';
import { RegainQuestionsComponent } from './regain-questions/regain-questions.component';
import { DotReplacePipe } from './pipes/dot-replace.pipe';
import { FiveDigitPipe } from './pipes/number-digit.pipe';
import { LoaderComponent } from './loader/loader.component';
import { ProfileCardComponent } from './profile-card/profile-card.component';
import { DirectiveModules } from 'src/_directives/directives.module';
import { PnlRoundUpPipe } from './pipes/pnl-round-up.pipe';
import { ComingsoonComponent } from './comingsoon/comingsoon.component';

@NgModule({
  declarations: [
    SuccessMessageComponent,
    D3LineGraphComponent,
    BannerComponent,
    TradingDeskModalComponent,
    PieChartComponent,
    BuysellModalComponent,
    DataNotFoundComponent,
    TableLoaderComponent,
    CardLoadingComponent,
    TradersCardComponent,
    TradingviewwidgetComponent,
    TradingviewwidgetStocksComponent,
    HomePageComponent,
    SecurityQuestionsComponent,
    RegainQuestionsComponent,
    DotReplacePipe,
    FiveDigitPipe,
    PnlRoundUpPipe,
    LoaderComponent,
    ProfileCardComponent,
    ComingsoonComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HighchartsChartModule,
    FormsModule,
    ReactiveFormsModule,
    TradingviewWidgetModule,
    NgImageSliderModule,
    MatSelectInfiniteScrollModule,
    DirectiveModules

  ],

  exports: [
    MaterialModule,
    SuccessMessageComponent,
    D3LineGraphComponent,
    BannerComponent,
    TradingDeskModalComponent,
    PieChartComponent,
    BuysellModalComponent,
    DataNotFoundComponent,
    TableLoaderComponent,
    CardLoadingComponent,
    TradersCardComponent,
    TradingviewwidgetComponent,
    TradingviewwidgetStocksComponent,
    HomePageComponent,
    DotReplacePipe,
    FiveDigitPipe,
    PnlRoundUpPipe,
    LoaderComponent,
    ProfileCardComponent,
    ComingsoonComponent
  ],
})
export class SharedComponents {}
