const hostName = 'api.cryptocentric.co';
const TradeWatchName = 'tradewatch.cryptocentric.co';
const TradingDeskName = 'tradingdesk.cryptocentric.co';
const CopyTrader = 'copytrader.cryptocentric.co';
const PriceAlert = 'cryptoalerts.cryptocentric.co';
const Wallets = 'ethwallet.cryptocentric.co';
const Stocks = 'tradingdeskstocks.cryptocentric.co';
const Alerts = 'cryptoalerts.cryptocentric.co';

export const environment = {
  production: false,
  ApiBaseUrl: `https://${hostName}/api/v1.0`,
  ApiPublicUrl: `https://${hostName}`,
  ApiPricingUrl: `https://api.${TradeWatchName}`,
  ApiTradingDeskUrl: `https://${TradeWatchName}/api/v1.0`,
  ApiTradingBalanceUrl: `https://${TradingDeskName}/api/v1.0`,
  ApiTradingdeskStocks: `https://${Stocks}/api/v1.0`,
  ApiCopyTrade: `https://${CopyTrader}/api/v1.0`,
  ApiAlert: `https://${PriceAlert}/api/v1.0`,
  WebSocketUrl: `ws://${hostName}`,
  WebTradeSocketUrl: `ws://${TradeWatchName}`,
  adminControl: `https://${TradeWatchName}/api/v1.0/admin/usertradestatus`,
  MuleSoftApiUrl: 'https://149.28.163.219:8081/api',
  GoogleApiKey: 'AIzaSyDVBt1itZt1ZhOCQZFu9eyyh1AMR3Qn4bs',
  ApplicationName: 'Crypto Centric',
  ServerPath: 'https://cryptocentric.co',
  WalletsPath: `https://${Wallets}/api/v1.0`,
  AlertsPath: `https://${Alerts}/api/v1.0`,
};
