import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dotReplace',
  pure: true
})
export class DotReplacePipe implements PipeTransform {

  transform(value:string): unknown {
    if(value){
      let substring = value.substring(6,37);
      value = value.replace(substring,'...');
    }
    return value;
  }

}
