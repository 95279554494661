<mat-toolbar>
  <mat-toolbar-row
    style="justify-content: center; display: flex; align-items: center"
  >
    <img
      src="./assets/images/appLogos/Nlogo1.png"
      class="logo"
      (click)="toHome()"
    />
    <span class="buttons">
      <button (click)="notActive()"
        mat-button
        class="menuButton"
        routerLink="/dashboard"
        routerLinkActive="activebutton"
      >
        Dashboard
      </button>
      <button (click)="notActive()"
        mat-button
        class="menuButton"
        routerLink="/wallets"
        matTooltip="Coming Soon"
        routerLinkActive="activebutton"
      >
        Wallet
      </button>
      <button (click)="notActive()"
        mat-button
        class="menuButton"
        routerLink="/order_details"
        routerLinkActive="activebutton"
      >
        Orders
      </button>
      <!-- <button
        mat-button
        class="menuButton"
        routerLink="/market"
        routerLinkActive="activebutton"
      >
        Market
      </button> -->
      <button
        mat-button
        class="menuButton"
        [ngClass]="marketActive? 'activebutton' : 'menuButton'"
        [matMenuTriggerFor]="menu"
      >
        Market
      </button>
      <button (click)="notActive()"
        mat-button
        class="menuButton"
        matTooltip="Coming Soon"
        routerLink="/deposits"
        routerLinkActive="activebutton"
      >
        Deposits
      </button>
      <button (click)="notActive()"
        mat-button
        class="menuButton"
        routerLink="/trading_desk"
        routerLinkActive="activebutton"
      >
        Trading Desk
      </button>
      <button (click)="notActive()"
        mat-button
        class="menuButton"
        routerLink="/copy_trader"
        routerLinkActive="activebutton"
      >
        Copy Trader
      </button>
    </span>
    <span class="spacer"></span>

    <!-- <button
      mat-icon-button
      (click)="toggleDarkTheme()"
      *ngIf="darktheme"
      matTooltip="Switch to light theme"
      class="icon"
    >
      <mat-icon>wb_sunny</mat-icon>
    </button>
    <button
      mat-icon-button
      (click)="toggleDarkTheme()"
      *ngIf="!darktheme"
      matTooltip="Switch to dark theme"
      class="icon"
    >
      <mat-icon>brightness_2</mat-icon>
    </button> -->
    <!-- <mat-icon matBadge="15" matBadgeColor="warn">add_alert</mat-icon> -->
    <!-- <span class="spacer"></span> -->
    <img
      [ngClass]="this.profilePath == true ? 'activePath' : 'inActivePath'"
      [src]="imageUrl"
      [matMenuTriggerFor]="profileMenu"
    />
    <mat-menu #profileMenu="matMenu">
      <button mat-menu-item [routerLink]="['/profile/' + this.userId]">
        <mat-icon>view_headline</mat-icon>
        <span>Profile</span>
      </button>
      <!-- <button mat-menu-item routerLink="/authentication">
        <mat-icon routerLink="/authentication">verified_user</mat-icon>
        <span>MFA</span>
      </button>
      <button mat-menu-item routerLink="/kyc">
        <mat-icon>fingerprint</mat-icon>
        <span>KYC</span>
      </button> -->
      <button mat-menu-item [routerLink]="['/alerts']">
        <mat-icon>add_alert</mat-icon>
        <span>Alerts</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="marketOverview()">Market Overview</button>
  <button mat-menu-item (click)="market()">Market Trading</button>
</mat-menu>
