import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { AppEndPoints } from 'src/config/end-points.constants';
import { HeadersConstants } from 'src/config/headers.constants';
import {
  LoginData,
  RegistrationData,
  ResendEmail,
  ResendSMS,
  TokenGen,
  VerifyData,
  _login,
} from 'src/interfaces/user.interface';
import { Router } from '@angular/router';
import { LocalStorageProvider } from '../local-storage.provider';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isUserLoggedIn() {
    throw new Error('Method not implemented.');
  }

  public loggedIn = new BehaviorSubject<boolean>(false);
  private $emailRekey = new BehaviorSubject<string>('');
  emailre_key = this.$emailRekey.asObservable();

  private $mobileRekey = new BehaviorSubject<string>('');
  mobilere_key = this.$mobileRekey.asObservable();

  private $password = new BehaviorSubject<string>('');
  pass = this.$password.asObservable();

  constructor(
    public http: HttpClient,
    public router: Router,
    public _storageProvider: LocalStorageProvider
  ) {}

  register(data: RegistrationData) {
    const url = AppEndPoints.REGISTER;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders).pipe(
      map((user: any) => {
        if (user) {
          this._storageProvider.setNewUser(user.data.new_user);
          this.setemailRekey(user.data.EmailRekey);
          this.setmobileRekey(user.data.MobileRekey);
        }
        return user;
      })
    );
  }

  isAuthenticatedUser(): boolean {
    if (localStorage.getItem('authUser') == null) {
      return false;
    } else {
      return true;
    }
  }

  refreshToken(): Observable<Object> {
    const url = AppEndPoints.REFRESH_TOKEN;
    const httpOptions = {
      headers: new HttpHeaders({
        Email: this._storageProvider.AuthUserEmail,
        Refresh: this._storageProvider.getRefreshKey(),
        Authorization: localStorage.getItem('authUser') as string,
      }),
    };

    return this.http.get(url, httpOptions).pipe(
      map((newToken) => {
        if (newToken) {
          this._storageProvider.setAuthUser(newToken);
          this.loggedIn.next(true);
        }
        return newToken;
      })
    );
  }

  verifyEmail(emailVerify_data: VerifyData) {
    const url = AppEndPoints.VERIFY_EMAIL;
    return this.http.post(
      url,
      emailVerify_data,
      HeadersConstants.noTokenHeaders
    );
  }

  verifyMobile(mobileVerify_data: VerifyData) {
    const url = AppEndPoints.VERIFY_MOBILE;
    return this.http.post(
      url,
      mobileVerify_data,
      HeadersConstants.noTokenHeaders
    );
  }

  setemailRekey(email_rk: string) {
    this.$emailRekey.next(email_rk);
  }

  setmobileRekey(mob_rk: string) {
    this.$mobileRekey.next(mob_rk);
  }

  setPass(pass: string) {
    // this.$password.next(Buffer.from(pass).toString('base64'));
    this.$password.next(pass);
  }

  resendemail(data: ResendEmail) {
    const url = AppEndPoints.RESEND_EMAIL;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  resendsms(data: ResendSMS) {
    const url = AppEndPoints.RESEND_SMS;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  reqLogin(data: LoginData) {
    const url = AppEndPoints.LOGIN_REQ;
    return this.http.post(url, data, HeadersConstants.noTokencustomer);
  }

  login(data: _login) {
    const url = AppEndPoints.LOGIN;
    return this.http.post(url, data, HeadersConstants.noTokencustomer).pipe(
      map((auth: any) => {
        if (auth) {
          this._storageProvider.setAuthUser(auth);
          localStorage.setItem('authUser', auth.data.token);
          this._storageProvider.clearRegisteredUser();
          this.loggedIn.next(true);
        }
        return auth;
      })
    );
  }

  genarateToken(data: TokenGen) {
    const url = AppEndPoints.GENERATE_TOKEN;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders).pipe(
      map((res: any) => {
        localStorage.setItem('authUser', res.data.token);
        return res;
      })
    );
  }

  authenticateUser(userId: string) {
    const url = AppEndPoints.VERIFY_TOKEN + userId;
    return this.http.get(url, HeadersConstants.tockenHeader);
  }

  logout() {
    this.loggedIn.next(false);
    this._storageProvider.clearAllStorageData();
    this.router.navigate(['/home']);
  }

  setupMFA(data: any) {
    let url = AppEndPoints.SETUP_GAUTH;
    return this.http.post(url, data, HeadersConstants.tockenHeader);
  }

  enableMFA(data: any) {
    let url = AppEndPoints.ENABLE_GAUTH;
    return this.http.post(url, data, HeadersConstants.tockenHeader);
  }

  disableMFA(data: any): Observable<any> {
    const url = AppEndPoints.DISABLE_GAUTH;
    return this.http.post(url, data);
  }

  verifyCode(data: any): Observable<any> {
    let url = AppEndPoints.ACCESS_GAUTH;
    return this.http.post(url, data, HeadersConstants.tockenHeader);
  }

  updateProfilePicture(data: any, userId: any) {
    let url = AppEndPoints.PROFILE_UPLOAD + userId;
    return this.http.post(url, data, HeadersConstants.tockenHeader);
  }

  updateProfile(data: any) {
    let url = environment.ApiBaseUrl + '/user/profile/update';
    return this.http.post(url, data, HeadersConstants.tockenHeader);
  }

  getpic(userId: any) {
    const url = AppEndPoints.VERIFY_TOKEN + userId;
    return this.http.get(url, HeadersConstants.RoleCustomerHeaders);
  }

  resetPasswordCreation(data: any) {
    const url = AppEndPoints.VERIFY_PASSWORD_RESET;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  changePasswordCreation(data: any) {
    const url = AppEndPoints.CHANGE_PASSWORD_REQ;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  getSecurityQuestions() {
    const url = AppEndPoints.SECURITY_QUESTIONS;
    return this.http.get(url, HeadersConstants.RoleCustomerHeaders);
  }

  saveMyAnswers(data: any) {
    let url = environment.ApiBaseUrl + '/user/addSecurityQuestions';
    return this.http.post(url, data, HeadersConstants.tockenHeader);
  }

  loginWithOtp(data: any) {
    let url = AppEndPoints.LOGIN_OTP;
    return this.http.post(url, data, HeadersConstants.RoleCustomerHeaders);
  }

  getForgotPassword(data: any) {
    let url = AppEndPoints.FORGOT_PASSWORD;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  forgotPasswordEmailOtp(data: any) {
    let url = AppEndPoints.FORGOT_PASSWORD_EOTP;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  forgotPasswordMobileOtp(data: any) {
    let url = AppEndPoints.FORGOT_PASSWORD_MOTP;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  verifyPassword(data: any) {
    let url = AppEndPoints.VERIFY_PASSWORD;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  resendEmailOtp(data: any) {
    let url = AppEndPoints.FORGOT_PASSWORD_RESEND_EOTP;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  resendMobileOtp(data: any) {
    let url = AppEndPoints.FORGOT_PASSWORD_RESEND_MOTP;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  resetPassEmailOtp(data: any) {
    let url = AppEndPoints.RESET_PASSWORD_EOTP;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  changePasswordResendOTP(data:any){
    let url = AppEndPoints.RESEND_EMAIL_OTP_CHANGEPASSWRD;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  updateKeysEmailOTP(data: any) {
    let url = AppEndPoints.SEND_EMAIL_MANAGEAPIKEYUPDATE;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  updateKeys(data: any) {
    let url = AppEndPoints.UPDATE_SECRET;
    return this.http.post(url, data, HeadersConstants.noTokenHeaders);
  }

  // redirectIfAuthenticated(userId: string) {
  //   if (this.loggedIn) {
  //     this.router.navigate(['/home/' + userId]);
  //   }
  // }

  createAlerts(data: any){
    let url = AppEndPoints.CREATE_ALERTS;
    return this.http.post(url,data, HeadersConstants.noTokenHeaders);
  }

  getUserAlerts(userId : any){
    let url = AppEndPoints.GET_USER_ALERTS + userId ;
    return this.http.get(url, HeadersConstants.noTokenHeaders);
  }

  deleteAlerts(userId : any,alertId :any){
    let url = AppEndPoints.DELETE_ALERTS  + userId + '&alertId=' + alertId  ;
    return this.http.delete(url,HeadersConstants.noTokenHeaders);
  }

  updateAlert(userId : any,data:any){
    let url = AppEndPoints.UPDATE_ALERTS + userId ;
    return this.http.put(url,data,HeadersConstants.noTokenHeaders);
  }

  getAlert(alertId: any){
    let url = AppEndPoints.GET_ALERT + alertId ; 
    return this.http.get(url,HeadersConstants.noTokenHeaders)
  }
}
