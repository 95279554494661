<div class="container">
  <mat-card class="card" (click)="navigate()">
    <div class="row">
      <img [src]="transform(profPic)" class="profile" />
      <div class="col">
        <div class="username">{{ name }}</div>
        <div
          style="
            display: flex;
            justify-content: start;
            margin-left: 10px !important;
          "
        >
          <img
            src="./../../assets/icon/Group.png"
            style="width: 12px; height: 12px;margin-top: 10px;"
          />
          <div class="copycats">{{ copiersCount }}</div>
        </div>
      </div>
      <!-- <div>
        <mat-icon
          *ngIf="!wishlisted"
          class="wishlist"
          (click)="changeWhishList()"
          >star_border</mat-icon
        >
        <mat-icon
          *ngIf="wishlisted"
          class="wishlist"
          (click)="changeWhishList()"
          >star</mat-icon
        >
      </div> -->
      <div *ngIf="!isCopied">
        <button
          mat-raised-button
          class="copyButton"
          (click)="navigate()"
        >
          Copy
        </button>
      </div>
    </div>

    <mat-card-content>
      <div style="margin: 15% 0px 0px 2% !important">
        <div [ngClass]="returns >= 0 ? 'positiveReturns' : 'negativeReturns'">
          {{ returns }}%
        </div>
        <div class="returns-sub">Returns</div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
