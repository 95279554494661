<div class="container">
  <mat-toolbar>
    <mat-toolbar-row
      style="justify-content: center; display: flex; align-items: center"
    >
      <img
        src="./assets/images/appLogos/Nlogo1.png"
        class="logo"
        (click)="toHome()"
      />
      <span class="buttons">
        <button mat-button class="menuButton" (click)="changePage('signup')">
          Wallet
        </button>
        <button
          mat-button
          class="menuButton"
          routerLinkActive="activebutton"
          [matMenuTriggerFor]="menu"
        >
          Market
        </button>
        <button mat-button class="menuButton" (click)="changePage('signup')">
          Deposits
        </button>
      </span>
      <span class="spacer" style="margin-left: 300px !important"></span>
      <button
        mat-button
        class="button login"
        (click)="changePage('login')"
        [ngClass]="activeButton == 'login' ? 'activeBtn' : 'button'"
      >
        Login
      </button>
      <button
        mat-button
        class="button signup"
        (click)="changePage('signup')"
        [ngClass]="activeButton == 'signup' ? 'activeBtn' : 'button'"
      >
        Sign up
      </button>
      <!-- <button mat-icon-button (click)="toggleDarkTheme()" *ngIf="darktheme" matTooltip="Switch to light theme"
      class="icon">
      <mat-icon>wb_sunny</mat-icon>
     </button>
     <button mat-icon-button (click)="toggleDarkTheme()" *ngIf="!darktheme" matTooltip="Switch to dark theme"
      class="icon">
      <mat-icon>brightness_2</mat-icon>
    </button> -->
    </mat-toolbar-row>
  </mat-toolbar>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="marketOverview()">Market Overview</button>
    <button mat-menu-item (click)="market()">Market Trading</button>
  </mat-menu>
</div>
