import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'cc-line-graph',
  templateUrl: './d3-line-graph.component.html',
  styleUrls: ['./d3-line-graph.component.scss'],
})
export class D3LineGraphComponent implements OnInit {
  @Input() showX: any;
  @Input() showY: any;
  @Input() data: any;
  @Input() color:string;
  Highcharts = Highcharts;
  linechart: any;
  constructor() { }

  ngOnInit(): void {
   
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      this.linechart = {
        series: [
          {
            color: this.color,
            data: changes.data.currentValue
          },
        ],
        exporting: {
          enabled: false
        },
        chart: {
          type: 'line',
        },
        title: false,
        subtitle: false,
        xAxis: {
          visible: this.showX,
          title: false,
          labels: {
            enabled: this.showX,
          },
          gridLineColor: 'transparent',
        },
        yAxis: {
          title: false,
          labels: {
            enabled: this.showY,
          },
          gridLineColor: 'transparent',
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          line: {
            marker: {
              enabled: false,
            },
          },
        },
        credits: {
          enabled: false,
        },
      };
    }

  }
}
