<div class="main-container">
  <cc-loader *ngIf="loader"></cc-loader>
  <span *ngIf="!loader">
    <div *ngIf="!profileSelected">
      <div *ngIf="copiedTraderList.length == 0">
        You have not copied any trader till now..
      </div>
      <div *ngIf="copiedTraderList.length > 0">Traders you have copied</div>
      <div
        *ngIf="copiedTraderList.length > 0"
        style="display: flex; flex-wrap: wrap"
      >
        <cc-traders-card
          *ngFor="let investor of copiedTraderList; let i = index"
          [name]="investor.userName"
          [profPic]="investor.profilePic"
          [traderID]="investor.traderId"
          [copiersCount]="investor.copycatsCount"
          [returns]="investor.pnlInPercentage"
          [wishlisted]="investor.wishlisted"
          [isCopied]="investor.isCopied"
        ></cc-traders-card>
      </div>
    </div>
    <cc-loader *ngIf="loader"></cc-loader>
    <span *ngIf="!loader">
      <div *ngIf="profileSelected">
        <profile-card
          [component]="'copier-area'"
          [data]="traderDetails"
          (messageEvent)="receiveMessage($event)"
        ></profile-card>
        <mat-card
          style="
            width: 73vw;
            margin-top: 40px;
            padding: 25px 0px !important;
            box-shadow: none;
          "
        >
          <div
            style="
              font-family: cc-highlightFont;
              font-size: 20px;
              font-weight: 500;
              text-align: left;
              margin-left: 50px;
            "
          >
            Copied pairs
          </div>
          <mat-form-field
            appearance="outline"
            style="margin-top: 2%; margin-left: 50px"
          >
            <mat-label>Select Exchange</mat-label>
            <mat-select [(ngModel)]="selectedExchange" (selectionChange)="changeExchange($event)">
              <mat-option
                *ngFor="let broker of selectedPairs"
                [value]="broker.exchange"
              >
                {{ broker.exchange | titlecase }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="example-table-container">
            <table
              mat-table
              [dataSource]="selectedPairsData"
              style="text-align: center"
            >
              <ng-container matColumnDef="pair">
                <th mat-header-cell *matHeaderCellDef>Coin Pair</th>
                <td mat-cell *matCellDef="let element" class="cell">
                  {{ element.pair }}
                </td>
              </ng-container>

              <ng-container matColumnDef="limit">
                <th mat-header-cell *matHeaderCellDef>Limit Amount</th>
                <td mat-cell *matCellDef="let element" class="cell">
                  <mat-form-field appearance="legacy">
                    <input
                      matInput
                      [(ngModel)]="element.quantityRiskPerTrade"
                      (ngModelChange)="updateValues(element.pair, $event)"
                      [disabled]="!element.edit"
                      [value]="element.quantityRiskPerTrade"
                    />
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="trades">
                <th mat-header-cell *matHeaderCellDef>No Of Trades</th>
                <td mat-cell *matCellDef="let element" class="cell">
                  {{ element.totalTradePlaced }}0
                </td>
              </ng-container>

              <ng-container matColumnDef="pnl">
                <th mat-header-cell *matHeaderCellDef>Profit & loss</th>
                <td mat-cell *matCellDef="let element" class="cell">
                  <span
                    [ngClass]="
                      element.pnlInPercentage >= 0
                        ? 'profit_class'
                        : 'loss_class'
                    "
                    >0 %</span
                  >
                </td>
              </ng-container>

              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef>Status</th>
                <td mat-cell *matCellDef="let element">
                  <mat-slide-toggle
                    [checked]="element.status == 'enabled'"
                    [disabled]="!element.toggle"
                    (change)="toggleChange($event, element)"
                  ></mat-slide-toggle>
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element; let i = index">
                  <span>
                    <mat-icon
                      class="icon"
                      matTooltip="edit data"
                      *ngIf="!element.edit"
                      (click)="enableEdit(element)"
                      style="
                        color: #2b2c34;
                        background-color: #ece7f1;
                        justify-content: center;
                        align-items: center;
                        display: flex;
                        border-radius: 7px;
                      "
                      >edit</mat-icon
                    >
                    <mat-icon
                      class="icon"
                      *ngIf="element.edit"
                      (click)="doneEdit(element)"
                      style="color: green"
                      matTooltip="save data"
                      >done</mat-icon
                    >
                  </span>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: displayedColumns"
                [ngClass]="{ 'edit-row': row.edit }"
              ></tr>
            </table>
          </div>

          <mat-paginator
            style="background-color: #f4f7fcbf"
            [pageSizeOptions]="[5, 10, 20]"
            showFirstLastButtons
            aria-label="Select page of periodic elements"
          >
          </mat-paginator>
        </mat-card>
      </div>
    </span>
  </span>
</div>
