import historyProvider from './history-provider';
import stream from './stream';

const supportedResolutions = ['1', '60', 'D'];

const config = {
  supported_resolutions: supportedResolutions
};

// @ts-ignore
export default {
  onReady: (cb:any) => {
    // console.log('=====onReady running');
    setTimeout(() => cb(config), 0);

  },
  searchSymbols: (userInput:any, exchange:any, symbolType:any, onResultReadyCallback:any) => {
    // console.log('====Search Symbols running');
  },
  resolveSymbol: (symbolName:any, onSymbolResolvedCallback:any, onResolveErrorCallback:any) => {
    // expects a symbolInfo object in response
    // console.log('======resolveSymbol running:', {symbolName});
    const split_data = symbolName.split('/');
    // console.log({split_data});
    const symbol_stub = {
      name: symbolName,
      description: '',
      type: 'crypto',
      session: '24x7',
      timezone: 'Australia/Sydney',
      ticker: symbolName,
      exchange: '',
      minmov: 1,
      minmov2: 0,
      pointvalue: 1,
      pricescale: 100000000,
      has_intraday: true,
      intraday_multipliers: ['1', '60'],
      supported_resolution: supportedResolutions,
      volume_precision: 8,
      data_status: 'streaming',
    };

    if (split_data[1].match(/USD|EUR|JPY|AUD|GBP|KRW|CNY|USDT/)) {
      symbol_stub.pricescale = 100;
    }
    setTimeout(function () {
      onSymbolResolvedCallback(symbol_stub);
      // console.log('Resolving that symbol....', symbol_stub);
    }, 0);


    // onResolveErrorCallback('Not feeling it today')

  },
  getBars: function(symbolInfo:any, resolution:any, from:any, to:any, onHistoryCallback:any, onErrorCallback:any, firstDataRequest:any) {
    // console.log('=====getBars running');
    // console.log('function args',arguments)
    // console.log(`Requesting bars between ${new Date(from * 1000).toISOString()} and ${new Date(to * 1000).toISOString()}`)
    historyProvider.getBars(symbolInfo, resolution, from, to, firstDataRequest,2000)
      .then((bars:any) => {
        if (bars.length) {
          onHistoryCallback(bars, {noData: false});
        } else {
          onHistoryCallback(bars, {noData: true});
        }
      }).catch((err:any)=> {
      // console.log('bars-errrrrrr', {err});
      onErrorCallback(err);
    });

  },
  subscribeBars: (symbolInfo:any, resolution:any, onRealtimeCallback:any, subscribeUID:any, onResetCacheNeededCallback:any) => {
    // console.log('=====subscribeBars runnning');
    stream.subscribeBars(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback);
  },
  unsubscribeBars: (subscriberUID:any) => {
    // console.log('=====unsubscribeBars running');

    stream.unsubscribeBars(subscriberUID);
  },
  calculateHistoryDepth: (resolution:any, resolutionBack:any, intervalBack:any) => {
    // optional
    // console.log('=====calculateHistoryDepth running');
    // while optional, this makes sure we request 24 hours of minute data at a time
    // CryptoCompare's minute data endpoint will throw an error if we request data beyond 7 days in the past, and return no data
    return resolution < 60 ? {resolutionBack: 'D', intervalBack: '1'} : undefined;
  },
  getMarks: (symbolInfo:any, startDate:any, endDate:any, onDataCallback:any, resolution:any) => {
    // optional
    // console.log('=====getMarks running');
  },
  getTimeScaleMarks: (symbolInfo:any, startDate:any, endDate:any, onDataCallback:any, resolution:any) => {
    // optional
    // console.log('=====getTimeScaleMarks running');
  },
  getServerTime: (cb:any) => {
    // console.log('=====getServerTime running');
  }
};


