<div class="buysection" *ngIf="showContainer == 'buy'">
  <p>
  <mat-toolbar style="background-color: #20ce98">
    <span>BUY</span>
    <span class="spacer"></span>
    <mat-slide-toggle color="primary" (change)="buyToggle($event.checked)">SELL</mat-slide-toggle>
  </mat-toolbar>
  </p>
  <p>
  <mat-radio-group class="radio">
    <mat-radio-button value="M">Market</mat-radio-button>
    <mat-radio-button value="L">Limit</mat-radio-button>
  </mat-radio-group>
  </p>
  <p>
    <mat-form-field appearance="outline">
      <mat-label>XRP</mat-label>
      <input matInput placeholder="Placeholder" />
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>USDT</mat-label>
      <input matInput placeholder="Placeholder" />
    </mat-form-field>
  </p>
  <button mat-flat-button class="action" style="background-color: #20ce98">
    BUY
  </button>
</div>

<div class="sellsection" *ngIf="showContainer == 'sell'">
  <p>
  <mat-toolbar style="background-color: #fd7474">
    <span>SELL</span>
    <span class="spacer"></span>
    <mat-slide-toggle color="primary">BUY</mat-slide-toggle>
  </mat-toolbar>
  </p>
  <p>
  <mat-radio-group class="radio">
    <mat-radio-button value="M">Market</mat-radio-button>
    <mat-radio-button value="L">Limit</mat-radio-button>
  </mat-radio-group>
  </p>
  <p>
    <mat-form-field appearance="outline">
      <mat-label>XRP</mat-label>
      <input matInput placeholder="Placeholder" />
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>USDT</mat-label>
      <input matInput placeholder="Placeholder" />
    </mat-form-field>
  </p>
  <button mat-flat-button class="action" style="background-color: #fd7474">
    SELL
  </button>
</div>
