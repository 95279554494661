import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
  HttpUserEvent,
  HttpProgressEvent,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap, finalize } from 'rxjs/operators';
import { AuthService } from 'src/providers/http/auth.service';
import { LocalStorageProvider } from 'src/providers/local-storage.provider';
import { SnackbarService } from 'src/providers/http/snack-bar.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(
    public _authService: AuthService,
    private _storageProvider: LocalStorageProvider,
    private snackbar: SnackbarService
  ) {}

  /**
   * Clone the request and set required headers
   * @param req: Http Request
   */
  cloneRequest(req: HttpRequest<any>): HttpRequest<any> {
    let headers = req.headers;
    let clonedReq = req;

    // Don't set token in headers if the request doesn't require
    if (headers.get('noToken') === 'noToken') {
      headers = headers.delete('Authorization').delete('noToken');
      clonedReq = req.clone({ headers: headers });
    } else {
      clonedReq = req.clone({
        setHeaders: {
          Authorization: this._storageProvider.getToken(),
        },
      });
    }
    return clonedReq;
  }

  /**
   * Intercept the request and handle if any error related to jwt token occurs in the response
   * @param req: Http Request
   * @param next: What next to handle
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    return next.handle(this.cloneRequest(req)).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          switch ((<HttpErrorResponse>error).status) {
            case 400:
              return this._handle400Error(error);
            case 401:
              return this.handle401Error(req, error, next);
            default:
              return throwError(() => error);
          }
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }


    /**
   * handle if user sends bad requests like no token or invalid refresh token
   * @param error: Http response error
   */
    _handle400Error(error: HttpErrorResponse) {
      if (error.error.data.message === "INVALID") {
        this.snackbar.error('Your session has been expired, please login again to continue')
        this._authService.logout();
        return throwError(() => error);
      }
      return throwError(() => error);
    }

    private handle401Error(
      request: HttpRequest<any>,
      error: HttpErrorResponse,
      next: HttpHandler
  ) {
      if (error.error?.data?.message === 'jwt expired') {
          if (!this.isRefreshing) {
              this.isRefreshing = true;
              // Reset here so that the following requests wait until the token
              // comes back from the refreshToken call.
              this.refreshTokenSubject.next(null);
  
              return this._authService.refreshToken().pipe(
                  switchMap((refreshedToken: any) => {
                      if (refreshedToken) {
                          this.refreshTokenSubject.next(refreshedToken.data);
                          this._storageProvider.setAuthUser(refreshedToken.data);
                          return next.handle(this.cloneRequest(request));
                      } else {
                          // Optionally, handle the case where the refresh token call fails
                          this._authService.logout();
                          return throwError(() => error);
                      }
                  }),
                  catchError((err) => {
                      // Optionally, handle the error in refreshing token
                      this._authService.logout();
                      return throwError(() => err);
                  }),
                  finalize(() => {
                      this.isRefreshing = false;
                  })
              );
          } else {
              return this.refreshTokenSubject.pipe(
                  filter((refreshedToken) => refreshedToken != null),
                  take(1),
                  switchMap(() => next.handle(this.cloneRequest(request)))
              );
          }
      } else {
          // Handle other errors or optionally log out
          // this._authService.logout();
          return throwError(() => error);
      }
  }

  // /**
  //  * handle if user sends bad requests like no token or invalid refresh token
  //  * @param error: Http response error
  //  */
  // _handle400Error(error: HttpErrorResponse) {
  //   console.log(error)
  //   if (error.error.data.message === 'INVALID') {
  //     this.snackbar.error('Your session has been expired, please login again to continue')
  //     this._authService.logout();
  //     //   return <any>(
  //     //     throwError({
  //     //       error: {
  //     //         message:
  //     //           'Your session has been expired, please login again to continue.',
  //     //       },
  //     //     })
  //     //   );
  //     return throwError(() => error);
  //   }
  //   return <any>throwError(() => error);
  // }

  //   private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
  //     if (!this.isRefreshing) {
  //       this.isRefreshing = true;
  //       this.refreshTokenSubject.next(null);

  //       return this._authService.refreshToken().pipe(
  //         switchMap((token: any) => {
  //           this.isRefreshing = false;
  //           this.refreshTokenSubject.next(token['result'].accessToken);
  //           return next.handle(
  //             this.addToken(request, token['result'].accessToken)
  //           );
  //         })
  //       );
  //     } else {
  //       return this.refreshTokenSubject.pipe(
  //         filter((token) => token != null),
  //         take(1),
  //         switchMap((jwt) => {
  //           return next.handle(this.addToken(request, jwt));
  //         })
  //       );
  //     }
  //   }
  // }
}

export const tokenInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true,
};
