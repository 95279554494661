import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageProvider } from 'src/providers/local-storage.provider';

@Component({
  selector: 'cc-data-not-found',
  templateUrl: './data-not-found.component.html',
  styleUrls: ['./data-not-found.component.scss'],
})
export class DataNotFoundComponent implements OnInit {
  cards = [
    {
      name: 'Deposit AUD',
      icon: './../../assets/images/Vector 12.png',
      complited: false,
      button: 'Deposit AUD',
      code: 1,
    },
    {
      name: 'Add your API keys',
      icon: './../../assets/images/noun-password-security-429191112.png',
      complited: false,
      button: 'Add API keys',
      code: 2,
    },
    {
      name: 'Select exchange, coin pair and start trading',
      icon: './../../assets/images/Group 126115275612.png',
      complited: false,
      button: 'Start trading',
      code: 3,
    },
  ];

  verificationCards = [
    {
      name: 'Authenticator',
      desc: 'Create your google authenticator by downloading the Authenticator app from the app store.',
      button: 'Enable authenticator',
    },
    {
      name: 'Complete verification',
      desc: 'Complete the basic KYC process to enable transactions above $900.',
      button: 'Complete basic KYC',
    },
  ];
  constructor(private router: Router, private _ls: LocalStorageProvider) {}

  ngOnInit(): void {}

  trading() {
    let userdata = this._ls.getUserProfile();
    if (userdata.unique_ids.length == 0) {
      this.router.navigate(['/manage_apikey']);
    } else if (userdata.unique_ids.length > 0) {
      this.router.navigate(['/trading_desk']);
    }
  }

  cardAction(code: number) {
    if (code == 1) {
      this.router.navigate(['/deposits']);
    } else if (code == 2) {
      this.router.navigate(['/manage_apikey']);
    } else if (code == 3) {
      this.trading();
    }
  }

  navTo(value: any) {
    if ('Enable authenticator' == value) {
      this.router.navigate(['/authentication']);
    } else if ('Complete basic KYC' == value) {
      this.router.navigate(['/kyc']);
    }
  }
}
