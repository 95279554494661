import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { defaultImage } from 'src/_commonDatas/image.const';
import { CopytraderService } from 'src/providers/http/copytrader.service';
import { SharedService } from 'src/providers/http/shared.service';
import { SnackbarService } from 'src/providers/http/snack-bar.service';
import { LocalStorageProvider } from 'src/providers/local-storage.provider';

@Component({
  selector: 'cc-copier-area',
  templateUrl: './copier-area.component.html',
  styleUrls: ['./copier-area.component.scss'],
})
export class CopierAreaComponent implements OnInit, OnDestroy {
  brokers: any;
  selectedValue: string;
  displayedColumns: string[] = [
    'pair',
    'limit',
    'trades',
    'pnl',
    'status',
    'action',
  ];
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  profileSelected: boolean;
  traderDetails: any;
  userId: any;
  userDetails: any;
  copiedTraderList: any[] = [];
  tradingPairs: any;
  traderId: string;
  allFilteredPair: any[];
  selectedPairs: any;
  selectedPairsData: any[];
  pair: string;
  exchange: string;
  quantityRiskPerTrade: number;
  selectedExchange: any;
  checked: boolean;
  enabledisablestatus: string;
  loader: boolean;
  default: boolean;
  constructor(
    private router: Router,
    private _snackbar: SnackbarService,
    private _shared: SharedService,
    private _copyTrader: CopytraderService,
    private _sanitizer: DomSanitizer,
    private _storageProvider: LocalStorageProvider
  ) {}

  ngOnInit(): void {
    this.loader = true;
    this.userId = this._storageProvider.getLoggedInUserInfo().userId;
    // this._shared.currentTab$.subscribe((res: any) => {
    // if (res == 2) {
    // this.profileSelected = true;
    // this._shared.currentTrader$.subscribe((res: any) => {
    //   let ids = {
    //     traderId: res,
    //   };
    //   this.getTraderDetails(ids);
    // });
    // } else {
    this.profileSelected = false;
    this.getAllTraders();
    // }
    // });
  }

  // getAllCopiedTraders() {
  //   this._copyTrader.copedTraders(this.userId).subscribe({
  //     next: (res: any) => {
  //       console.log(res);
  //     },
  //     error: (err: any) => {
  //       console.log(err);
  //     },
  //   });
  // }

  receiveMessage($event: any) {
    if ($event == 'profile') {
      this.navigate();
    }
  }

  getAllTraders() {
    this.userDetails = this._storageProvider.getAuthUser();
    this._copyTrader
      .getAllTraders(this._storageProvider.getLoggedInUserInfo().userId)
      .subscribe({
        next: (res: any) => {
          let data = res.data.tradersList.filter(
            (trader: any) => trader.traderId != this.userDetails.userId
          );
          this.copiedTraderList = data.filter((trader: any) => trader.isCopied);
        },
        error: (err: any) => {
          this._snackbar.error(err.data.message);
        },
      });
    this.loader = false;
  }

  getTraderDetails(data: any) {
    this.loader = true;
    this.traderId = data.traderId;
    this._copyTrader.getTraderProfile(data, this.userId).subscribe({
      next: (res: any) => {
        if (res.status == 'success') {
          this.traderDetails = res.data.TraderProfile;
          this.tradingPairs = this.traderDetails.tradingPairs;
          this.getCopiedPairs();
        }
      },
      error: (err: any) => {
        this._snackbar.error(err.error);
      },
    });
    // this.loader = false;
  }

  navigate() {
    this.router.navigate(['/copy_trader/trader-profile/' + this.traderId]);
  }

  transform(profilePic: any) {
    if (profilePic == null) {
      return 'data:image/jpg;base64,' + defaultImage;
    }
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      'data:image/jpg;base64,' + profilePic
    );
  }

  ngAfterViewInit() {
    this.default = true;
    this._shared.currentTrader$.subscribe((res) => {
      if (res != null) {
        this.profileSelected = true;
        let ids = {
          traderId: res,
        };
        this.getTraderDetails(ids);
      }
    });
    // ELEMENT_DATA.forEach((ele) => {
    //   Object.assign({ edit: false }, ele);
    // });
    // this.dataSource.paginator = this.paginator;
    // this.getExchanges();
  }

  back() {
    this.router.navigate(['/copy_trader']);
  }

  ngOnDestroy(): void {
    this._shared.setTrader(null);
  }

  getExchanges() {
    this._shared.getExchanges().subscribe({
      next: (res: any) => {
        if (res.status == 'success') {
          this.brokers = res.data.brokerOrExchangesList;
          this.selectedValue = this.brokers[0].brokerOrExchangeCode;
        }
      },
      error: (err: any) => {
        this._snackbar.error(err.error.data.message);
      },
    });
  }

  enableEdit(element: any) {
    element.edit = true;
    element.toggle = true;
  }

  toggleChange(event: any, data: any) {
    if (event.checked == true) {
      this.enabledisablestatus = 'enabled';
    } else if (event.checked == false) {
      this.enabledisablestatus = 'disabled';
    }
  }

  enableDisable(event: any, data: any) {
    let datas = {
      userId: this.userId,
      selectedPair: {
        pair: data.pair,
        exchange: this.selectedExchange,
        quantityRiskPerTrade: data.quantityRiskPerTrade,
      },
      status: this.enabledisablestatus,
    };
    this._copyTrader.addCoinPairs(this.traderId, datas).subscribe({
      next: (res: any) => {
        if (event.checked == true) {
          this._snackbar.info('Pairs Enabled');
        } else if (event.checked == false) {
          this._snackbar.info('Pairs Disabled');
          // this.selectedPairsData = res
        }
      },
      error: (err: any) => {
        this._snackbar.error(err.data.message);
      },
    });
  }

  doneEdit(element: any) {
    element.edit = false;
    this.addingCoinPairs(element);
    // this._snackbar.info(
    //   'Limit amount for ' + element.coinpair + ' is Updated !!!'
    // );
  }

  convertPercentage(percentage: string): number {
    return parseFloat(percentage.replace('%', ''));
  }

  changeExchange(event: any) {
    this.default = false;
    this.selectedExchange = event.value;
    this.getCopiedPairs();
    // data.forEach((ele: any) => {
    //   Object.assign({ edit: false }, ele)
    // });
    // this.dataSource = new MatTableDataSource<any>(data);
    // this.dataSource.paginator = this.paginator;
  }

  getCopiedPairs() {
    this._copyTrader.copiedPairs(this.traderId, this.userId).subscribe({
      next: (res: any) => {
        this.selectedPairs = res.data.groupedPairs;
        if (this.selectedPairs.length > 0) {
          if (this.default) {
            this.selectedExchange = this.selectedPairs[0].exchange;
          }
        }
        this.selectedPairs.forEach((ele: any) => {
          this.selectedPairs[0].pairs.forEach(
            (data: any) => (data.pnlInPercentage = 0)
            // (data.pnlInPercentage = this.convertPercentage(
            //   data.pnlInPercentage
            // ))
          );
          //  ele.pnlInPercentage = this.convertPercentage(ele.pnlInPercentage);
          if (ele.exchange === this.selectedExchange) {
            this.selectedPairsData = ele.pairs;
            this.tradingPairs.filter(
              (data: any) => data.exchange == this.selectedExchange
            );
            let index: number;
            if (this.default) {
              index = 0;
            } else {
              index = this.selectedPairs.findIndex(
                (data: any) => data.exchange === this.selectedExchange
              );
            }
            const results = this.tradingPairs[index].pairs.filter(
              (value: any) =>
                !this.selectedPairsData.some(
                  (value1: any) => value.pair === value1.pair
                )
            );
            if (results.length > 0) {
              results.forEach((ele: any) => {
                Object.assign(ele, {
                  status: 'disabled',
                  quantityRiskPerTrade: 0,
                  toggle: false,
                  newData: true,
                });
                this.selectedPairsData.push(ele);
                this.dataSource.paginator = this.paginator;
              });
            }
          }
        });
      },
      error: (err: any) => {
        this._snackbar.error(err.data.message);
      },
    });
    this.loader = false;
  }

  updateValues(pair: string, newValue: number) {
    this.pair = pair;
    this.quantityRiskPerTrade = newValue;
  }

  addingCoinPairs(element: any) {
    let data;
    if (element.newData == undefined) {
      let qrt: any;
      if (this.quantityRiskPerTrade == undefined) {
        this.quantityRiskPerTrade = element.quantityRiskPerTrade;
      }
      data = {
        userId: this.userId,
        selectedPair: {
          pair: element.pair,
          exchange: this.selectedExchange,
          quantityRiskPerTrade: this.quantityRiskPerTrade,
        },
        status: this.enabledisablestatus,
      };
    } else if (element.newData) {
      data = {
        userId: this.userId,
        selectedPair: {
          pair: this.pair,
          exchange: this.selectedExchange,
          quantityRiskPerTrade: this.quantityRiskPerTrade,
        },
        status: this.enabledisablestatus,
      };
    }

    this._copyTrader.addCoinPairs(this.traderId, data).subscribe({
      next: (res: any) => {
        this._snackbar.info(res.data.message);
        element.toggle = false;
      },
      error: (err: any) => {
        this._snackbar.error(err.data.message);
        element.toggle = false;
      },
    });
  }
}
