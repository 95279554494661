<div class="footerDiv" >
    <svg class="svg" height="500" viewBox="0 0 1439 536" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M253.5 29.4011C128.014 9.52751 32.3333 27.4011 0 39.9011V535.901H1439V20.4012C1401 -4.09879 1266 -10.0988 1128.5 22.4012C991 54.9012 733.534 76.4012 700 76.4012C570 76.4012 364 46.9014 253.5 29.4011Z"
            fill="#D1D1E9" />
    </svg>

    <div class="web-view">
        <footer class="site-footer">
            <div class="container-fluid ">
                <div class="row wrap links-sec">

                    <div class="col-1">
                        <ul class='footer-content no-bullets'>
                            <li><a href="" class="li-heading">About Us</a></li>
                            <li><a href="" class="li-content">About</a></li>
                            <li><a href="" class="li-content">Careers</a></li>
                            <li><a href="" class="li-content">Business Contacts</a></li>
                            <li><a href="" class="li-content">Community</a></li>
                            <li><a href="" class="li-content">Coin Centric Blog</a></li>
                            <li><a href="" class="li-content"  [routerLink]="['/terms-conditions']"  >Terms</a></li>
                            <li><a href="" class="li-content">Privacy</a></li>
                            <li><a href="" class="li-content">Announcements</a></li>
                        </ul>
                    </div>
                    <div class="col-2">
                        <ul class='footer-content no-bullets'>
                            <li><a href="" class="li-heading">Products</a></li>
                            <li><a href="" class="li-content">Exchange</a></li>
                            <li><a href="" class="li-content">Academy</a></li>
                            <li><a href="" class="li-content">Charity</a></li>
                            <li><a href="" class="li-content">Card</a></li>
                            <li><a href="" class="li-content">Info</a></li>
                            <li><a href="" class="li-content">Labs</a></li>
                            <li><a href="" class="li-content">Launchpad</a></li>
                            <li><a href="" class="li-content">Research</a></li>
                        </ul>
                    </div>
                    <div class="col-3">
                        <ul class='footer-content no-bullets'>
                            <li><a href="" class="li-heading">Service</a></li>
                            <li><a href="" class="li-content">Downloads</a></li>
                            <li><a href="" class="li-content">Buy Crypto</a></li>
                            <li><a href="" class="li-content">Institutional Services</a></li>
                            <li><a href="" class="li-content">Referral</a></li>
                            <li><a href="" class="li-content">BNB</a></li>
                            <li><a href="" class="li-content">OTC Trading</a></li>
                            <li><a href="" class="li-content">Listing Application</a></li>
                            <li><a href="" class="li-content">P2P Merchant Application</a></li>
                        </ul>
                    </div>
                    <div class="col-4">
                        <ul class='footer-content no-bullets'>
                            <li><a href="" class="li-heading">Support</a></li>
                            <li><a href="" class="li-content">Support Center</a></li>
                            <li><a href="" class="li-content">Give Us Feedback</a></li>
                            <li><a href="" class="li-content">Submit a Request</a></li>
                            <li><a href="" class="li-content">API Documentation</a></li>
                            <li><a href="" class="li-content">Fees</a></li>
                            <li><a href="" class="li-content">Trading Rules</a></li>
                            <li><a href="" class="li-content">Coin Centric Verify</a></li>
                            <li><a href="" class="li-content">Law Enforcement Requests</a></li>
                        </ul>
                    </div>
                    <div class="col-5">
                        <ul class='footer-content no-bullets'>
                            <li><a href="" class="li-heading">Learn</a></li>
                            <li><a href="" class="li-content">Buy BNB</a></li>
                            <li><a href="" class="li-content">Buy BUSD</a></li>
                            <li><a href="" class="li-content">Buy Bitcoin</a></li>
                            <li><a href="" class="li-content">Buy Ethereum</a></li>
                            <li><a href="" class="li-content">Buy Ripple</a></li>
                            <li><a href="" class="li-content">Buy Litecoin</a></li>
                            <li><a href="" class="li-content">Buy Bitcoin Cash</a></li>
                            <li><a href="" class="li-content">Buy Dogecoin</a></li>
                        </ul>
                    </div>
                    <div class="col-6 iconColm">
                        <ul class='footer-content no-bullets'>
                            <li><img src="./assets/images/appLogos/Nlogo1.png" class="logo" alt="logo2"></li>
                            <li class="Centric">
                                <div class="Rectangle-2">
                                    
                                    <a href=" https://twitter.com/deliverycentric" target="_blank" class='sIcons'><img
                                            src="./assets/imgs/twitter.svg" class='sIcons'></a>
                                    <a href="  https://www.facebook.com/deliverycentric" target="_blank"
                                        class='sIcons'><img src="./assets/imgs/socialicon3.svg" class='sIcons'></a>
                                    <a href="   https://www.instagram.com/delivery.centric/" target="_blank"
                                        class='sIcons'><img src="./assets/imgs/socialicon4.svg" class='sIcons'></a>
                                    <a><img src="./assets/imgs/linkedin.svg" class='sIcons'></a>

                                </div>
                            </li>
                            <li> <button mat-button class="requestbtn">Submit a request</button></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</div>