import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fiveDigit'
})
export class FiveDigitPipe implements PipeTransform {
  transform(value: number): string {
    // Convert the number to a string and extract the first 5 characters
    const stringValue = value.toString();
    return stringValue.substring(0, 5);
  }
}
