import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'cc-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent implements OnInit {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'pie',
      backgroundColor: '#FFFFFF',
      width: 290,
      height: 220,
    },
    title: {
      // text: 'Pie-Chart',
      text: null, 
    },
    legend: {
      floating: true,
      align: 'right',
      layout: 'vertical',
      verticalAlign: 'top',
      itemMarginTop: 10,   
      itemMarginBottom: 10,  
      width: 300,
      x: 250,
      y: 50,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '{point.percentage:.0f}%', 
          distance: -30, 
          style: {
            color: 'white', 
            textOutline: '0px contrast', 
          }
        },
        showInLegend: true,
      },
    },
    series: [
      {
        type: 'pie',
        data: [
          {
            name: 'AUD',
            y: 30,
            color: 'rgba(110, 123, 145, 1)' 
          },
          {
            name: 'Crypto',
            y: 35,
            color: 'rgba(221, 227, 238, 1)' 
          },
          {
            name: 'Stocks',
            y: 35,
            color: 'rgba(98, 70, 234, 1)'  
          },
        ],
        center: ['30%', '50%'], 
        size: '80%', 
      },
    ],
    exporting: {
      enabled: false
    }
  };
  constructor() {}

  ngOnInit(): void {}
}
