<div class="container">
  <div class="cards" *ngFor="let item of cards">
    <div class="card is-loading">
      <div class="image"></div>
      <!-- <div class="content">
        <h2></h2>
        <p></p>
      </div> -->
    </div>
  </div>
</div>
