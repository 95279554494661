<mat-card class="profile-card">
  <mat-card-content>
    <div
      fxLayout="row"
      fxLayoutAlign="space-evenly center"
      style="height: 120px;justify-content: center;align-items: center;display: flex;"
    >
      <div>
        <div fxLayout="row" fxLayoutAlign="start center">
          <img
            [src]="backgroundImageUrl"
            style="
              background-size: cover;
              width: 80px;
              height: 80px;
              border: solid 8px #d9d9d9;
              border-radius: 50%;
            "
          />
          <div style="padding-left: 4%; width: 250px;">
            <div class="trader-name">
              <span *ngIf="component == 'trader-area'">{{
                traderDetails.userName
              }}</span>
              <span 
                *ngIf="
                  component == 'copier-area' || component == 'trader-profile'
                "
                >{{ traderDetails.username }}</span
              >
            </div>
            <div class="timeline">
              Trader since
              {{ traderDetails.copyTraderSince | date : "yyyy" }}
            </div>
          </div>
        </div>
      </div>
      <div>
        <div style="padding-left: 2%; width: 250px;">
          <div class="trader-name">
            {{ traderDetails.TotalTradePlaced }}
          </div>
          <div class="followesHeading">Total trades</div>
        </div>
      </div>
      <div>
        <div style="padding-left: 2%; width: 250px;">
          <div class="trader-name">
            {{ traderDetails.copycatsCount }}
          </div>
          <div class="followesHeading">Total followers</div>
        </div>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions style="margin-top: -10px;">
    <button
      mat-button
      class="viewProfileBtn"
      *ngIf="component == 'copier-area'"
      (click)="sendMessage('profile')"
    >
      View Profile
    </button>
    <span>
      <!-- <button
        mat-button
        class="profile-btn"
        *ngIf="component == 'trader-profile' && !traderDetails.isCopied"
        (click)="sendMessage('copy')"
      >
        Copy
      </button> -->
      <button
        mat-button
        class="profile-btn"
        *ngIf="component == 'trader-profile' && traderDetails.isCopied"
        (click)="sendMessage('uncopy')"
      >
        Un-Copy
      </button>
      <button
        mat-button
        class="profile-btn"
        *ngIf="component == 'trader-profile' && traderDetails.isCopied"
        (click)="sendMessage('ca')"
      >
        Copy Area
      </button>
    </span>
  </mat-card-actions>
</mat-card>
