import { environment } from '../environments/environment';

class AppHost {
  public static get baseURL(): string {
    return environment.ApiBaseUrl;
  }

  public static get copyTraderURL(): string {
    return environment.ApiCopyTrade;
  }

  public static get publicURL(): string {
    return environment.ApiPublicUrl;
  }
  public static get pricingURL(): string {
    return environment.ApiPricingUrl;
  }

  public static get tradingDeskURL(): string {
    return environment.ApiTradingDeskUrl;
  }

  public static get tradingBalanceURL(): string {
    return environment.ApiTradingBalanceUrl;
  }

  public static get copytraderURL(): string {
    return environment.ApiCopyTrade;
  }

  public static get walletURL(): string {
    return environment.WalletsPath;
  }

  public static get stocksURL(): string {
    return environment.ApiTradingdeskStocks;
  }

  public static get alertsURL(): string {
    return environment.AlertsPath;
  }
}

export class AppEndPoints {
  // market api's
  public static GET_MARKET_DATA = AppHost.baseURL + '/public/exchange';

  // Getting market table graph data
  public static GET_GRAPH_DATA = AppHost.baseURL + '/public/getGraph/';

  // getting logo og the selected coin
  public static GET_LOGO = AppHost.baseURL + '/public/getCryptoLogo/';

  //Auth api's
  public static REGISTER = AppHost.baseURL + '/newuser/signup';

  // verify Email
  public static VERIFY_EMAIL = AppHost.baseURL + '/newuser/verify/email';

  // verify Mobile
  public static VERIFY_MOBILE = AppHost.baseURL + '/newuser/verify/mobile';

  //resend eamil
  public static RESEND_EMAIL = AppHost.baseURL + '/newuser/resend/email';

  //resend SMS
  public static RESEND_SMS = AppHost.baseURL + '/newuser/resend/sms';

  //Logn request
  public static LOGIN_REQ = AppHost.baseURL + '/user/loginreq';

  //Login
  public static LOGIN = AppHost.baseURL + '/user/login';

  //genarate token
  public static GENERATE_TOKEN = AppHost.baseURL + '/newuser/generate/token';

  //Refresh token
  public static REFRESH_TOKEN = AppHost.baseURL + '/user/refresh';

  //User Profile details
  public static VERIFY_TOKEN = AppHost.baseURL + '/user/profile?userId=';

  //User Orders
  public static ALL_ORDERS = AppHost.tradingDeskURL + '/trading/userorders?';

  //User PnL
  public static USER_PNL = AppHost.tradingDeskURL + '/trading/profitloss?';

  // convert market value to USDT
  public static CONVERTTOUSDT =
    AppHost.tradingDeskURL + '/trading/priceConversion';

  //get pnl order details
  public static GETDETAILS = AppHost.tradingDeskURL + '/trading/getordersbytid';

  //Trading-desk
  //gettin brokers or exchanges
  public static GETBROKERS =
    AppHost.baseURL + '/brokerOrExchanges/getBrokerOrExchanges';

  //get base asset
  public static GET_BASEASSETS =
    AppHost.baseURL +
    '/brokerOrExchanges/getSymbolDetails?brokerOrExchangeCode=';

  //get balances on pair
  public static GET_BALANCE =
    AppHost.tradingBalanceURL + '/trading/webhook/getBalance';

  //get balances of all coin
  public static GET_ALL_COIN_BALANCE =
    AppHost.tradingBalanceURL + '/trading/webhook/getBalance?allcoins=true';

  //Users
  public static GET_UNIQUE_KEYS =
    AppHost.baseURL + '/user/trading/getUniqueIds?email=';

  // get price of a pair from binnance
  public static GET_BINANCE_BALANCE =
    AppHost.baseURL + '/public/priceFromBinnace/';

  // Place Trade
  public static WEBHOOK = AppHost.tradingDeskURL + '/trading/webhook';

  // get all copytraders
  public static GETTRADERS =
    AppHost.copytraderURL + '/trading/getallcopytraders?userId=';

  //get trader Profile details
  public static TraderDetails =
    AppHost.copytraderURL + '/trading/getTraderPairs?traderId=';

  // get traderProfile
  public static TraderProfile =
    AppHost.copytraderURL + '/trading/getTraderProfile?';

  // setup MFA qrcode
  public static SETUP_GAUTH = AppHost.baseURL + '/user/google/setupMFA';
  // enable MFA qrcode
  public static ENABLE_GAUTH = AppHost.baseURL + '/user/google/enableMFA';
  // disable MFA qrcode
  public static DISABLE_GAUTH = AppHost.baseURL + '/user/google/disableMFA';
  // verify Acess MFA
  public static ACCESS_GAUTH = AppHost.baseURL + '/user/google/verifyAccess';
  // manage API keys
  public static BROKEROREXCHANGES =
    AppHost.baseURL + '/brokerOrExchanges/getBrokerOrExchanges';
  public static BROKEROREXCHANGESINPUTS =
    AppHost.baseURL +
    '/brokerOrExchanges/getBrokerOrExchange?brokerOrExchangeCode=';
  public static SAVE_API_KEYS = AppHost.baseURL + '/user/trading/saveapikeys';
  public static DELETE_API_KEYS =
    AppHost.baseURL + '/user/trading/deleteapikeys';
  // profile
  public static PROFILE_UPLOAD =
    AppHost.baseURL + '/user/profile/picture?userId=';

  // resetPasword
  public static VERIFY_PASSWORD_RESET =
    AppHost.baseURL + '/user/forgotPWD/verifyPWD';
  // security questions
  public static SECURITY_QUESTIONS =
    AppHost.baseURL + '/user/securityQuestions';

  // forgot password
  public static FORGOT_PASSWORD = AppHost.baseURL + '/user/forgotPWD/email';
  public static FORGOT_PASSWORD_EOTP = AppHost.baseURL + '/user/forgotPWD/eotp';
  public static FORGOT_PASSWORD_MOTP = AppHost.baseURL + '/user/forgotPWD/motp';
  public static VERIFY_PASSWORD = AppHost.baseURL + '/user/forgotPWD/verifyPWD';
  public static FORGOT_PASSWORD_RESEND_EOTP =
    AppHost.baseURL + '/user/resend/email';
  public static FORGOT_PASSWORD_RESEND_MOTP =
    AppHost.baseURL + '/user/resend/sms';
//loginOTP
public static LOGIN_OTP = AppHost.baseURL + '/user/loginreq/mobileOTP';
  //enable as copytrader
  public static ENABLECOPYTRADER =
    AppHost.copyTraderURL + '/trading/copytrader/updateKYCAndTermsStatus';

  // Get trader exchanges
  public static GET_USER_EXCHANGES =
    AppHost.copyTraderURL + '/trading/copytrader/getexchanges?';

  // Get trader traded pair with exchange
  public static GET_USER_TRADED_PAIRS =
    AppHost.copyTraderURL + '/trading/getTraderPairs?';

  // enable and disable coin pair to copytrader
  public static ENABLE_DISABLE_PAIR =
    AppHost.copyTraderURL + '/trading/copytrader/updatecoinpair';

  //enable copy cat
  public static ENABLE_COPY_TRADER =
    AppHost.copyTraderURL + '/trading/copycat/enable';

  public static GET_ALL_COPIED_TRADERS =
    AppHost.copytraderURL + '/trading/copycat/getCopyedTraders';

  public static GET_ALL_COPIED_PAIRS =
    AppHost.copyTraderURL + '/trading/copycat/getpairs?';

  public static ADD_COINPAIRS =
    AppHost.copyTraderURL + '/trading/copycat/add_coinpairs?';

  public static UNFOLLOW_TRADER =
    AppHost.copyTraderURL + '/trading/copycat/unfollow?';
  //Stocks
  public static SEARCHSTOCKS = AppHost.stocksURL + '/trading/';

  public static POSITIONANDHOLDINGS = AppHost.stocksURL + '/trading';

  public static GETSTOCKDETAILS = AppHost.stocksURL + '/trading/';

  public static GETQUOTE = AppHost.stocksURL + '/trading/';

  public static GETMARGIN = AppHost.stocksURL + '/trading/iifl/getmargin?';

  public static IIFLmargins = AppHost.stocksURL + '/trading/iifl/getmargin?uniqueid=';

  public static ZERODHAmargins = AppHost.stocksURL + '/trading/zerodha/getmargin?uniqueid='

  //ALERTS
  public static GET_USER_ALERTS = AppHost.alertsURL + '/alerts/getUserAlerts?userId=';

  public static CREATE_ALERTS = AppHost.alertsURL + '/alerts/createalert';

  public static DELETE_ALERTS = AppHost.alertsURL + '/alerts/deleteAlert?userId=';

  public static UPDATE_ALERTS = AppHost.alertsURL + '/alerts/updateAlert?userId=';

  public static GET_ALERT = AppHost.alertsURL + '/alerts/getalert?alertId='

  // Change password email OTP
  public static RESET_PASSWORD_EOTP =
    AppHost.baseURL + '/user/resetpassword/emailotp';

  public static CHANGE_PASSWORD_REQ = AppHost.baseURL + '/user/resetPassword';

  // Resend email OTP
  public static RESEND_EMAIL_OTP_CHANGEPASSWRD = AppHost.baseURL+'/user/resend/emailotp'

  // manage api key update password and secret based on stocks borker

  public static SEND_EMAIL_MANAGEAPIKEYUPDATE =
    AppHost.baseURL + '/user/trading/updateapikeys/emailotp';

  public static UPDATE_SECRET =
    AppHost.baseURL + '/user/trading/updateapikeys';
}
