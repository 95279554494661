<div class="main-container" >
  <div class="order-table-container">
    <!-- <div class="header">Order history</div> -->
      <img src="./../../assets/images/paper1.png" class="centerImg" />
      <span class="center content">No order placed or created.</span>
      <span style="margin-top: 20px;">
        <button mat-raised-button class="tradingBtn" (click)="trading()">Start trading</button>
      </span>
  </div>
  <div class="depositsContainer">
    <span class="header2 margin5">
      Place a trade to check for orders, profit, and loss.
    </span>
    <div>
      <span class="sub-heading">How it works</span>
      <div class="flex-row" style="margin-top: 20px !important;">
        <mat-card *ngFor="let card of cards" class="stepsCards">
          <div class="statusView">
            <span *ngIf="!card.complited" class="pending"> Pending </span>
            <span *ngIf="card.complited"> Complited </span>
          </div>
          <div class="parent">
            <div class="div1">
              <img [src]="card.icon" width="65" height="60" />
            </div>
            <div class="div2">{{ card.name }}</div>
            <div class="div3">
              <button mat-stroked-button class="actionButton" (click)="cardAction(card.code)">
                {{ card.button }}
              </button>
            </div>
          </div>
        </mat-card>
      </div>
      <!-- <span>
        <mat-card *ngFor="let card of cards" class="stepsCards">
          <div class="statusView">
            <span *ngIf="!card.complited" class="pending"> Pending </span>
            <span *ngIf="card.complited"> Complited </span>
          </div>
          <div>
            <img [src]="card.icon" width="100" height="80" />
            <div>{{ card.name }}</div>
          </div>
          <button mat-stroked-button class="actionButton">
            {{ card.button }}
          </button>
        </mat-card>
      </span> -->
    </div>
    <div style="margin-top: 5%">
      <span
        style="
          color: #263238;
          font-family: 'cc-regularFont';
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 30px;
        "
        >Complete your basic verification</span
      >
      <span style="display: flex;">
        <mat-card
          *ngFor="let card of verificationCards"
          class="verificationCard"
        >
          <div class="verificationHeading">
            {{ card.name }}
          </div>
          <div class="verificationDesc">
            {{ card.desc }}
          </div>
          <button mat-stroked-button class="verificationButton" (click)="navTo(card.button)">
            {{ card.button }}
          </button>
        </mat-card>
      </span>
    </div>
  </div>
</div>
