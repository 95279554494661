import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pnlRoundUp',
})
export class PnlRoundUpPipe implements PipeTransform {
  transform(value: string, currency: string): string {
    const trimmedValue = value.slice(0, -4);
    const numericValue = parseFloat(trimmedValue);
    const formattedValue = numericValue.toFixed(5);
    return `${formattedValue} ${currency}`;
  }
}
