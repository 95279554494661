import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/providers/http/auth.service';
import { SharedService } from 'src/providers/http/shared.service';
import { SnackbarService } from 'src/providers/http/snack-bar.service';
import { LocalStorageProvider } from 'src/providers/local-storage.provider';

@Component({
  selector: 'home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
})
export class HomeHeaderComponent implements OnInit {
  darktheme = false;
  userId: string;
  activeComponent: string = 'login'
  marketActive: boolean;
  activeButton:string = null;
  constructor(
    private _auth: AuthService,
    public router: Router,
    private _storageProvider: LocalStorageProvider,
    private _snackbar: SnackbarService,
    private _shared: SharedService,
  ) {}

  ngOnInit(): void {
    this.userId = this._storageProvider.getLoggedInUserInfo().userId;
  }

  changePage(component: string) {
    this._shared.setComponent(component)
    this.router.navigate(['/login']);
    this.activeButton = component;
    // console.log(this.activeButton,"ghug")
  }

  toggleDarkTheme(): void {
    this.darktheme = !this.darktheme;
    document.body.classList.toggle('dark-theme');
  }

  toHome() {
    if (localStorage.getItem('authUser') != null) {
      this.router.navigate(['/home/' + this.userId]);
    } else if (localStorage.getItem('authUser') == null) {
      this.router.navigate(['/home']);
    }
    this.activeButton = null;
  }

  marketOverview() {
    if (localStorage.getItem('authUser') != null) {
      this.router.navigate(['/market/' + this.userId + '/market-overview/']);
    } else if (localStorage.getItem('authUser') == null) {
      this.router.navigate(['/market/market-overview']);
    }
    this.marketActive = true;
    this.activeButton = null;
  }

  market() {
    if (localStorage.getItem('authUser') != null) {
      this.router.navigate(['/market/' + this.userId]);
    } else if (localStorage.getItem('authUser') == null) {
      this._snackbar.info('Please Login for trading!!!');
      this.router.navigate(['/login']);
    }
    this.marketActive = true;
    this.activeButton = null;
  }

  // isLoginActive(): boolean {
  //   return this.router.isActive('/login', true);
  // }

  // isSignupActive(): boolean {
  //   return this.router.isActive('/sign_up', true);
  // }
}
