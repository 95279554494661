import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppEndPoints } from '../../config/end-points.constants';
import { HeadersConstants } from '../../config/headers.constants';

@Injectable({
  providedIn: 'root'
})
export class MarketService {

  constructor(public http: HttpClient) { }

  getMarketData(){
    let url = AppEndPoints.GET_MARKET_DATA;
    return this.http.get(url);
  }

  getGraphData(symbol:any){
    const url = AppEndPoints.GET_GRAPH_DATA+symbol+"/1h/168";
    return this.http.get(url, HeadersConstants.noTokenHeaders);
  }

  getLogo(symbol:string){
    let url = AppEndPoints.GET_LOGO+"?symbol="+symbol;
    return this.http.get(url, HeadersConstants.noTokenHeaders);
  }

  // getLatestUSD() {
  //   const apiUrl = 'https://open.er-api.com/v6/latest/USD?apikey=0df7551b2b084ea9afe905c0ab95ca6a';
  //   return this.http.get(apiUrl);
  // }
  
}
