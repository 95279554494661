import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'mi-trading-desk-modal',
  templateUrl: './trading-desk-modal.component.html',
  styleUrls: ['./trading-desk-modal.component.scss'],
})
export class TradingDeskModalComponent implements OnInit {
  showContainer: any;
  constructor(
    public dialogRef: MatDialogRef<TradingDeskModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  buyToggle(event:boolean){
    if(event){
      this.showContainer == 'sell'
    }
    else{
      this.showContainer == 'buy'
    }
  }

  ngOnInit(): void {
    this.showContainer = this.data.direction;
  }
}
