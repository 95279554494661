import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderInterface } from 'src/interfaces/orderData.interface';
import { SharedService } from 'src/providers/http/shared.service';
import { SnackbarService } from 'src/providers/http/snack-bar.service';
import { TradingdeskService } from 'src/providers/http/tradingdesk.service';

@Component({
  selector: 'cc-buysell-modal',
  templateUrl: './buysell-modal.component.html',
  styleUrls: ['./buysell-modal.component.scss'],
})
export class BuysellModalComponent implements OnInit {
  buyorderForm: any;
  balance: any;
  showlowBalance: boolean;
  userId: any;
  copytraderId: string = null;
  toggleValue: boolean;
  checked: boolean;
  bucketOrder: number = 1;
  loader: boolean;
  activateSell: boolean;
  disableLimitOrder: boolean;
  showBreakoutStrategy: boolean;
  doubleLegged: any;
  constructor(
    public dialogRef: MatDialogRef<BuysellModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _shared: SharedService,
    private _form: FormBuilder,
    private _tradingdesk: TradingdeskService,
    private _snackbar: SnackbarService
  ) {}

  ngOnInit(): void {
    this._shared.tradingdesk$.subscribe((data) => {
      if (JSON.stringify(data) == '{}') {
        this.loader = true;
      } else {
        this.data = data;
        this.loader = false;
        if (data.direction == 'sell') {
          this.toggleValue = true;
          this.activateSell = true;
        } else if (data.direction == 'buy') {
          this.toggleValue = false;
          this.activateSell = false;
        }
        this._shared.getExchanges().subscribe({
          next: (res: any) => {
            let data = res.data.brokerOrExchangesList.filter(
              (broker: any) => broker.brokerOrExchangeName == this.data.broker
            );
            if (data[0].brokerOrExchangeSupportTypes == 'stocks') {
              this.showBreakoutStrategy = true;
              this.disableLimitOrder = true;
            }
          },
          error: (err: any) => {
            this._snackbar.error('Something caused error, retry');
          },
        });

        this.buyorderForm = this._form.group({
          amount: [this.data.price, [Validators.required]],
          number_of_coins: [1, [Validators.required]],
          market: ['M', [Validators.required]],
        });
      }

      this._shared.balanceOb$.subscribe((bal) => {
        this.balance = bal;
        this.checkForLowBal();
      });
    });
  }

  changebreakout(event: any) {
    this.doubleLegged = event.checked;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && charCode != 46 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  quantityChange(event: any) {
    let qtyChange = event.target.value;
    this.buyorderForm.get('amount').setValue(qtyChange * this.data.price);
    this.checkForLowBal();
  }

  bucketChange(event: any) {
    let bktChange = event.target.value;
    if (bktChange == 0) {
      this.bucketOrder = 1;
    } else {
      this.bucketOrder = bktChange;
    }
  }

  toggleChanged(event: any) {
    this.checked = event.checked;
  }

  toggleChanges(event: any) {
    this.checked = event.checked;
  }

  checkForLowBal() {
    // if (this.data.direction == 'buy') {
    //   if (this.buyorderForm.value.amount > this.balance) {
    //     this.showlowBalance = true;
    //   } else {
    //     this.showlowBalance = false;
    //   }
    // } else if (this.data.direction == 'sell') {
    //   if (this.buyorderForm.value.number_of_coins > this.balance) {
    //     this.showlowBalance = true;
    //   } else {
    //     this.showlowBalance = false;
    //   }
    // }
    this.showlowBalance = false;
  }

  percentChange(event: any) {
    let value = (event / 100) * this.balance;
    this.buyorderForm.get('number_of_coins').setValue(value);
    this.buyorderForm.get('amount').setValue(value * this.data.price);
    this.checkForLowBal();
  }

  trimSymboltillCPType(symbol: string, cpType: string) {
    const index = symbol.indexOf(cpType);
    if (index === -1) {
      return symbol;
    }
    return symbol.substring(0, index + 2);
  }

  placeTrade() {
    if (this.checked) {
      this.bucketOrder = this.bucketOrder;
    } else {
      this.bucketOrder = 1;
    }
    if (this.showBreakoutStrategy) {  //------> working on this order placing logic with eq and d
      if (this.data.instrument == 'D' && this.doubleLegged) {
      } else if (this.data.instrument == 'D' && !this.doubleLegged) {
        this.data.symbol = this.trimSymboltillCPType(
          this.data.symbol,
          this.data.symbol.split(' ')[4]
        );
      }
    }

    const placeOrderData: OrderInterface = {
      userId: this.data.userId,
      data: {
        uniqueId: this.data.uniqueId,
        broker: this.data.broker,
        symbol: this.data.symbol,
        orderType: this.buyorderForm.value.market,
        price: '0',
        quantity: this.buyorderForm.value.number_of_coins.toString(),
        direction: this.data.direction,
        exchange: this.data.exchange || null,
        instrument: this.data.instrument || null,
        expiryDate: this.data.expiryDate || null,
        strike: this.data.strike || null,
        right: this.data.right || null,
        uoid: null,
        userId: this.data.userId,
        masterOrderId: null,
        copyTradeId: this.data.copyTradeId,
        copyCatId: null,
        tradeType: this.data.tradeType,
        bucketOrder: this.bucketOrder,
      },
    };
    this._tradingdesk.placeOrder(placeOrderData).subscribe({
      next: (res: any) => {
        this._snackbar.success(res.data.message);
        this.dialogRef.close(true);
      },
      error: (err: any) => {
        this._snackbar.error(err.error.data.message);
        this.dialogRef.close(false);
      },
    });
  }

  changeToSell($event: any) {
    this.loader = true;
    // this.checked = $event.checked;
    this.data.direction = 'sell';
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }

  changeToBuy($event: any) {
    this.loader = true;
    // this.checked = $event.checked;
    this.data.direction = 'buy';
    setTimeout(() => {
      this.loader = false;
    }, 1000);
  }

  formatLabel(value: number): string {
    if (value <= 100) {
      return value + '%';
    }

    return `${value}`;
  }
}
