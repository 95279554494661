interface MyObject {
  [key: string]: string;
}
// const api_root = 'https://min-api.cryptocompare.com';
const api_root = 'https://api.binance.com/api/v3/klines?';
const history: MyObject = { key: 'value' };

function formatParams(params: any) {
  return (
    '?' +
    Object.keys(params)
      .map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&')
  );
}

function getRequest(url: string): Promise<any> {
  return new Promise<any>(function (resolve, reject) {
    const request = new XMLHttpRequest();
    request.onload = function () {
      if (this.status === 200) {
        resolve(this.response);
      } else {
        reject(new Error(this.statusText));
      }
    };
    request.onerror = function () {
      reject(new Error('XMLHttpRequest Error: ' + this.statusText));
    };
    request.open('GET', `${url}`);
    request.send();
  });
}

function getPrice(): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    const request = new XMLHttpRequest();
    request.open(
      'GET',
      'https://open.er-api.com/v6/latest/USD?apikey=0df7551b2b084ea9afe905c0ab95ca6a'
    );
    request.onload = function () {
      if (request.status === 200) {
        resolve(JSON.parse(request.responseText));
      } else {
        reject(new Error('Failed to fetch data'));
      }
    };
    request.onerror = function () {
      reject(new Error('Network error'));
    };
    request.send();
  });
}
// export default {
//   history: history,
//   getBars: (symbolInfo:any, resolution:any, from:any ,to:any, first:any, limit:any) => {
//     const split_symbol = symbolInfo.name.split('/');
//     let url : String
//     if(resolution === '60'){
//       url = 'interval=1h'
//     }
//     else if(resolution === '1'){
//       url ='interval=1m'
//     }
//     else if(resolution === 'D' || '1D'){
//       url = 'interval=1d'
//     }
//    return getRequest(`${api_root}symbol=${split_symbol[0]}${split_symbol[1]}&${url}&limit=2000`)
//       .then(d => {
//         const data = JSON.parse(d);
//         if (data.Response && data.Response === 'Error') {
//           return [];
//         }
//         if (data.length) {
//           const bars = data.map((el:any) => {
//             return {
//               time : el[0],
//               low:el[3] * 1.52423798503,
//               high:el[2] * 1.52423798503,
//               open:el[1] * 1.52423798503,
//               close:el[4] * 1.52423798503,
//               volume:el[5] * 1.52423798503
//             }
//           });
//           if (first) {
//             const lastBar = bars[bars.length - 1];
//           }
//           return bars;
//         } else {
//           return [];
//         }
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   }
// };

// export default {
//   history: history,
//   getBars: (
//     symbolInfo: any,
//     resolution: any,
//     from: any,
//     to: any,
//     first: any,
//     limit: any
//   ) => {
//     const split_symbol = symbolInfo.name.split('/');
//     let url: String;
//     if (resolution === '60') {
//       url = 'interval=1h';
//     } else if (resolution === '1') {
//       url = 'interval=1m';
//     } else if (resolution === 'D' || '1D') {
//       url = 'interval=1d';
//     }

//     getPrice().then((d:any) => {
//       console.log('Received data:', d.data.AUD);
//     })
//     .catch((error:any) => {
//       console.error('Error:', error);
//     }),

//     return getRequest(
//       `${api_root}symbol=${split_symbol[0]}${split_symbol[1]}&${url}&limit=2000`
//     )
//       .then((d) => {
//         const data = JSON.parse(d);
//         if (data.Response && data.Response === 'Error') {
//           return [];
//         }
//         if (data.length) {
//           const bars = data.map((el: any) => {
//             return {
//               time: el[0],
//               low: el[3] * 1.5203401673,
//               high: el[2] * 1.5203401673,
//               open: el[1] * 1.5203401673,
//               close: el[4] * 1.5203401673,
//               volume: el[5] * 1.5203401673,
//             };
//           });
//           if (first) {
//             const lastBar = bars[bars.length - 1];
//           }
//           return bars;
//         } else {
//           return [];
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   },
// };

export default {
  history: history,
  getBars: (
    symbolInfo: any,
    resolution: any,
    from: any,
    to: any,
    first: any,
    limit: any
  ) => {
    const split_symbol = symbolInfo.name.split('/');
    let url: String;
    if (resolution === '60') {
      url = 'interval=1h';
    } else if (resolution === '1') {
      url = 'interval=1m';
    } else if (resolution === 'D' || resolution === '1D') {
      url = 'interval=1d';
    }

    let price: any;
    return getPrice()
      .then((d: any) => {
        price = d.rates.AUD;
      })
      .catch((error: any) => {
        console.error('Error:', error);
      })
      .then(() => {
        return getRequest(
          `${api_root}symbol=${split_symbol[0]}${split_symbol[1]}&${url}&limit=2000`
        )
          .then((d) => {
            const data = JSON.parse(d);
            if (data.Response && data.Response === 'Error') {
              return [];
            }
            if (data.length) {
              const bars = data.map((el: any) => {
                return {
                  time: el[0],
                  low: el[3],
                  high: el[2],
                  open: el[1],
                  close: el[4],
                  volume: el[5],
                };
              });
              if (first) {
                const lastBar = bars[bars.length - 1];
                // Do something with lastBar if needed
              }
              return bars;
            } else {
              return [];
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
  },
};
