import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SnackbarService } from 'src/providers/http/snack-bar.service';
import { defaultImage } from './../../_commonDatas/image.const';
import { SharedService } from 'src/providers/http/shared.service';
import { CopierAreaComponent } from 'src/_modules/copyTrader/copier-area/copier-area.component';
@Component({
  selector: 'cc-traders-card',
  templateUrl: './traders-card.component.html',
  styleUrls: ['./traders-card.component.scss'],
})
export class TradersCardComponent implements OnInit {
  @Input() name: string;
  @Input() profPic: any;
  @Input() copiersCount: number;
  @Input() traderID: string;
  @Input() returns: any;
  @Input() wishlisted: boolean;
  @Input() isCopied: boolean;
  @ViewChild(CopierAreaComponent) copyTraderComponent!: CopierAreaComponent;

  constructor(
    private _sanitizer: DomSanitizer,
    private _snackbar: SnackbarService,
    private router: Router,
    private _shared: SharedService
  ) {}

  ngOnInit(): void {
    let returns = this.returns.substring(0, this.returns.length - 1);
    this.returns = Number(returns);
  }

  transform(profilePic: any) {
    if (profilePic == null) {
      return 'data:image/jpg;base64,' + defaultImage;
    }
    return this._sanitizer.bypassSecurityTrustResourceUrl(
      'data:image/jpg;base64,' + profilePic
    );
  }

  navigate() {
    this._shared.setCTTabClick(false);
    this._shared.currentTab$.subscribe(res => {
      if(res === 0){
        this.router.navigate(['/copy_trader/trader-profile/' + this.traderID]);
      }
      if(res === 2){
        this._shared.setTrader(this.traderID)
      }
    })
    // if (this.isCopied) {
    //   this._shared.setTrader(this.traderID)
    //   // this._shared.currentTab$.subscribe(res => {
    //   //  if(res == 0){
    //     this._shared.setState(2)
    //   //  }
    //   // })
    // } else {
     
      // this._shared.setState(0);
    // }
  }

  changeWhishList() {
    this.wishlisted = !this.wishlisted;
    let message = '';
    if (this.wishlisted) {
      message = 'Added ' + this.name + ' to wishlist';
    } else {
      message = this.name + ' removed from wishlist';
    }
    this._snackbar.info(message);
  }
}
