import { Injectable } from '@angular/core';
import { LocalStorageConstants } from '../config/local-storage.constants';

@Injectable()
export class LocalStorageProvider {
  //  -------------------New User-----------------
  setNewUser(new_user: { email: string; mobile: string }) {
    localStorage.setItem(
      LocalStorageConstants.NEW_USER,
      JSON.stringify(new_user)
    );
  }

  public getNewUser() {
    return localStorage.getItem(LocalStorageConstants.NEW_USER);
  }

  clearRegisteredUser() {
    localStorage.removeItem(LocalStorageConstants.REGISTERED_USER);
  }

  // ---------------Authorised User------------
  setAuthUser(auth_user: Object) {
    localStorage.setItem(
      LocalStorageConstants.AUTH_USER,
      JSON.stringify(auth_user)
    );
  }

  getAuthUser() {
    if (!!localStorage.getItem('authUser')) {
      return JSON.parse(
        localStorage.getItem(LocalStorageConstants.AUTH_USER) || '{}'
      );
    }
    return { token: '', ref: '' };
  }

  clearAuthUser() {
    localStorage.removeItem(LocalStorageConstants.AUTH_USER);
  }

  loggedIn() {
    return !!localStorage.getItem('authUser');
  }

  getToken() {
    return this.getAuthUser().token;
  }

  getRefreshKey() {
    return this.getAuthUser().ref;
  }

  // -------------------- Resend OTP  Key -------------------
  setResendOtpKey(re_key: string) {
    localStorage.setItem(LocalStorageConstants.RESEND_OTP_KEY, re_key);
  }

  getResendOtpKey() {
    if (!!localStorage.getItem(LocalStorageConstants.RESEND_OTP_KEY)) {
      return localStorage.getItem(LocalStorageConstants.RESEND_OTP_KEY);
    }
    return '';
  }

  clearResendOtpKey() {
    localStorage.removeItem(LocalStorageConstants.RESEND_OTP_KEY);
  }

  // ---------------------- User's Profile -------------------
  setUserProfile(user_profile: Object) {
    localStorage.setItem(
      LocalStorageConstants.USER_PROFILE,
      JSON.stringify(user_profile)
    );
  }

  getUserProfile() {
    if (!!localStorage.getItem(LocalStorageConstants.USER_PROFILE)) {
      return JSON.parse(
        localStorage.getItem(LocalStorageConstants.USER_PROFILE) as string
      );
    }
    return '';
  }

  get AuthUserEmail() {
    if (!!localStorage.getItem(LocalStorageConstants.USER_PROFILE)) {
      return JSON.parse(
        localStorage.getItem(LocalStorageConstants.USER_PROFILE) as string
      ).email;
    }
    return '';
  }

  get AuthUserMobile() {
    if (!!localStorage.getItem(LocalStorageConstants.USER_PROFILE)) {
      return JSON.parse(
        localStorage.getItem(LocalStorageConstants.USER_PROFILE) as string
      ).mobile;
    }
    return '';
  }

  // --------------- Clear local storage -------------------
  clearAllStorageData() {
    localStorage.clear();
  }

  getLoggedInUserInfo() {
    if (!!localStorage.getItem(LocalStorageConstants.USER_PROFILE)) {
      return JSON.parse(
        localStorage.getItem(LocalStorageConstants.USER_PROFILE) || '{}'
      );
    }

    return '';
  }

  setMFAToken(token: any, secret: any) {
    localStorage.setItem(
      LocalStorageConstants.GAUTH_TOKEN,
      JSON.stringify(token)
    );
    localStorage.setItem(
      LocalStorageConstants.GAUTH_SECRET,
      JSON.stringify(secret)
    );
  }

  getMFAToken() {
    const tokenObj = {
      qrUrl: JSON.parse(
        localStorage.getItem(LocalStorageConstants.GAUTH_TOKEN) || '{}'
      ),
      secretValue: JSON.parse(
        localStorage.getItem(LocalStorageConstants.GAUTH_SECRET) || '{}'
      ),
    };
    return tokenObj;
  }
}
