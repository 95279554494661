import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  durationInSeconds = 3;
  constructor(private _snackBar: MatSnackBar) {}

  error(message: string) {
    const snackBarRef: MatSnackBarRef<any> = this._snackBar.open(
      message,
      undefined,
      {
        panelClass: ['snackbar-error'],
        duration: this.durationInSeconds * 1000,
        horizontalPosition: 'right',
        verticalPosition: 'bottom',
      }
    );
    setTimeout(() => {
      snackBarRef.dismiss();
    }, this.durationInSeconds * 1000);
  }

  success(message: string) {
    const snackBarRef: MatSnackBarRef<any> = this._snackBar.open(message, ' ', {
      panelClass: ['snackbar-success'],
      duration: this.durationInSeconds * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
    setTimeout(() => {
      snackBarRef.dismiss();
    }, this.durationInSeconds * 1000);
  }

  info(message: string) {
    const snackBarRef: MatSnackBarRef<any> = this._snackBar.open(message, ' ', {
      panelClass: ['snackbar-info'],
      duration: this.durationInSeconds * 1000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
    });
    setTimeout(() => {
      snackBarRef.dismiss();
    }, this.durationInSeconds * 1000);
  }
}
