import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// _layout components
import { HomeLayoutComponent } from './_layout/home-layout/home-layout.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { AuthGuard } from './auth.guard';
import { PageNotFoundComponent } from 'src/_modules/page-not-found/page-not-found.component';

const routes: Routes = [
  // Home routes goes here
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        loadChildren: () =>
          import('./../_modules/home/home.module').then((m) => m.HomeModules),
        // data: { layout: 'layout1' }
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./../_modules/login/login.module').then(
            (m) => m.LoginModules
          ),
      },
      {
        path: 'sign_up',
        loadChildren: () =>
          import('./../_modules/signup/signup.module').then(
            (m) => m.SignUpModules
          ),
      },
      {
        path: 'verifyotp',
        loadChildren: () =>
          import('./../_modules/verify-otp/verify-otp.module').then(
            (m) => m.VerifyOtpModules
          ),
      },
      {
        path: 'market',
        loadChildren: () =>
          import('./../_modules/market/market.module').then(
            (m) => m.MarketModules
          ),
      },
      {
        path: 'forgotpassword',
        loadChildren: () =>
          import('./../_modules/forgotpassword/forgotpassword.module').then(
            (m) => m.ForgotpasswordModules
          ),
      },
      {
        path: 'terms-conditions',
        loadChildren: () =>
          import('./../_modules/terms-conditions/terms-conditions.module').then(
            (m) => m.TermsConditionsModules
          ),
      },
    ],
  },

  // Site routes goes here
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      {
        path: 'home/:userId',
        loadChildren: () =>
          import('./../_modules/home/home.module').then((m) => m.HomeModules),
        // data: { layout: 'layout2' }
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModules
          ),
      },
      {
        path: 'trading_desk',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/trading-desk/trading-desk.module').then(
            (m) => m.TradingDeskModules
          ),
      },
      {
        path: 'deposits',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/deposit/deposit.module').then(
            (m) => m.DepositsModules
          ),
      },
      {
        path: 'wallets',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/wallets/wallets.modules').then(
            (m) => m.WalletsModule
          ),
      },
      {
        path: 'trading_desk/:coinPair/:exchange',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/trading-desk/trading-desk.module').then(
            (m) => m.TradingDeskModules
          ),
      },
      {
        path: 'order_details',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/order-details/order-details.module').then(
            (m) => m.OrderDetailsModules
          ),
      },
      {
        path: 'copy_trader',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/copyTrader/copy-trader.module').then(
            (m) => m.CopyTraderModules
          ),
      },
      {
        path: 'manage_apikey',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/manage-api-keys/manage-api-keys.module').then(
            (m) => m.ManageApiKeysModules
          ),
      },
      {
        path: 'profile/:userId',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/profile/profile.module').then(
            (m) => m.ProfileModules
          ),
      },
      {
        path: 'kyc',
        loadChildren: () =>
          import('./../_modules/kyc/kyc.module').then((m) => m.KYCModules),
      },
      {
        path: 'authentication',
        loadChildren: () =>
          import('./../_modules/authantication/authantication.module').then(
            (m) => m.AuthenticationModules
          ),
      },
      {
        path: 'market/:userId',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./../_modules/market/market.module').then(
            (m) => m.MarketModules
          ),
      },
      {
        path: 'alerts',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import(
            './../_modules/alert-notification/alert-notification.module'
          ).then((m) => m.AlertNotificationModules),
      },
    ],
  },
  { path: '**', component: PageNotFoundComponent },
  //no layout routes

  //no layout routes
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
  constructor() {}
}
