import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HeadersConstants } from 'src/config/headers.constants';
import { AppEndPoints } from 'src/config/end-points.constants';
import { TradingDeskInterface } from 'src/interfaces/trading-desk.interface';
import { OrderInterface } from 'src/interfaces/orderData.interface';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TradingdeskService {
  constructor(private http: HttpClient) {}

  getBaseAssets(brokerOrExchangeCode: number, basecoinName: string) {
    let url: string;
    if (basecoinName == ' ') {
      url = AppEndPoints.GET_BASEASSETS + brokerOrExchangeCode;
    } else {
      url =
        AppEndPoints.GET_BASEASSETS +
        brokerOrExchangeCode +
        '&baseAsset=' +
        basecoinName;
    }
    return this.http.get(url, HeadersConstants.tockenHeader);
  }

  getAllCoinBalance(inputData: Object) {
    const url = AppEndPoints.GET_ALL_COIN_BALANCE;
    return this.http.post(
      url,
      inputData,
      HeadersConstants.RoleTradeCustomerHeaders
    );
  }

  getBalance(data: TradingDeskInterface) {
    const url = AppEndPoints.GET_BALANCE;
    return this.http.post(url, data, HeadersConstants.RoleTradeCustomerHeaders);
  }

  binancePrice(symbol: string) {
    const url = AppEndPoints.GET_BINANCE_BALANCE + symbol;
    return this.http.get(url, HeadersConstants.tockenHeader);
  }

  placeOrder(data: any) {
    const url = AppEndPoints.WEBHOOK;
    return this.http.post(url, data, HeadersConstants.plainHeader);
  }

  getMyExchanges() {
    let url = AppEndPoints.BROKEROREXCHANGES;
    return this.http.get(url, HeadersConstants.noTokenHeaders);
  }

  getMyExchangeInputs(brokerOrExchangeCode: any) {
    let url = AppEndPoints.BROKEROREXCHANGESINPUTS + brokerOrExchangeCode;
    return this.http.get(url, HeadersConstants.tockenHeader);
  }

  // methods for saving api keys
  saveApiKeys(data: any) {
    const url = AppEndPoints.SAVE_API_KEYS;
    return this.http.post(url, data, HeadersConstants.RoleCustomerHeaders).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getUniqueIds(email: any) {
    const url = `${AppEndPoints.GET_UNIQUE_KEYS}${email}`;
    return this.http.get(url, HeadersConstants.RoleCustomerHeaders);
  }

  deleteApiKeys(data: object) {
    const url = AppEndPoints.DELETE_API_KEYS;
    //console.log("is" + data )
    return this.http.post(url, data, HeadersConstants.RoleCustomerHeaders).pipe(
      map((res) => {
        return res;
      })
    );
  }

  //-------------------------------------- Stocks api ------------------------------------------
  searchStocks(broker: string, symbol: string) {
    let url =
      AppEndPoints.SEARCHSTOCKS + broker + '/getSymbols?symbol=' + symbol;
    return this.http.get(url);
  }

  getHoldingsAndPositions(broker: string, uniqueId: string) {
    let url = AppEndPoints.POSITIONANDHOLDINGS+'/'+broker+'/getHoldingsAndPositions?uniqueid='+uniqueId;
    return this.http.get(url);
  }

  getIIFLMargin(uniqueId:string){
    let url = AppEndPoints.IIFLmargins+uniqueId;
    return this.http.get(url);
  }

  getZERODHAMargin(uniqueId:string){
    let url = AppEndPoints.ZERODHAmargins+uniqueId;
    return this.http.get(url);
  }

  getSymbolDetails(broker: string, symbol: string) {
    let url =
      AppEndPoints.GETSTOCKDETAILS +
      broker +
      '/getSymbolDetails?symbol=' +
      symbol;
    return this.http.get(url);
  }

  getQuote(
    broker: string,
    uniqueId: string,
    exch: string,
    instrument: string,
    scripcode: number
  ) {
    let url =
      AppEndPoints.GETQUOTE +
      broker +
      '/getquote?uniqueid=' +
      uniqueId +
      '&exchange=' +
      exch +
      '&instrument=' +
      instrument +
      '&scripCode=' +
      scripcode;
    return this.http.get(url);
  }

  getMargin(uniqueId: string) {
    let url = AppEndPoints.GETMARGIN + 'uniqueid=' + uniqueId;
    return this.http.get(url);
  }
}
