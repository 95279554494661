<!-- ---------------------------------------------------------------------------------------------- -->
<div class="mainHome">
  <div style="width: 1200px">
    <div
      fxLayout="row"
      fxLayoutAlign="space-between  center"
      style="margin-left: -150px"
    >
      <div>
        <img src="./assets/imgs/coin.png" class="myHand" alt="" />
      </div>
      <div style="margin-left: -80px !important">
        <h1 class="imgContent">
          Maximize Your Profits with Our Advanced Trading Tools
        </h1>
        <p class="description1">
          Invest with confidence in cryptocurrencies on our reliable trading platform. 
          Enjoy easy buying, selling, and trading, while benefiting from real-time 
          market data, superior security, and round-the-clock assistance.
        </p>
        <div class="example-button-row" style="height: 200px">
          <button
            mat-button
            *ngIf="!isLogIn"
            class="Frame-1261152847"
            (click)="startTrading()"
          >
            Start trading
          </button>
        </div>
      </div>
    </div>
    <!-- ------------------------- -->
    <div>
      <h1 class="one-roof">All under one roof</h1>
      <hr class="Rectangle" />
      <div
        class="btnsList"
        fxLayout="row"
        fxLayoutAlign="space-around center"
      ></div>
      <div class="oneRoof2" fxLayout="row" fxLayoutAlign="space center">
        <div style="margin-right: -90px">
          <img class="tangle1" src="./assets/imgs/tangle1.png" alt="sdsadsad" />
          <h3 class="Podcast" style="margin-top: -25px">AUD Deposit</h3>
          <p class="Podcast-content">
            Our 'AUD Deposit' function at Coin Centric makes it easy to fund
            your trading account using Australian Dollars (AUD). Gain access to
            a transparent transaction history, fast and safe deposits, and local
            currency trading. Join us today to begin trading with confidence.
          </p>
        </div>
        <div>
          <img class="Vector1" src="./assets/imgs/Vector1.png" alt="sdsadsad" />
        </div>
        <div style="margin-right: -90px">
          <img class="tangle2" src="./assets/imgs/tangle2.png" alt="sdsadsad" />
          <h3 class="Podcast" style="margin-left: -70px">
            Trade-Crypto Deposit
          </h3>
          <p class="Podcast-content" style="margin-left: -70px">
            At Coin Centric our Crypto Deposit feature allows you to fund your
            trading account with various cryptocurrencies, offering flexibility,
            efficiency, and security for your trading journey. Enjoy cost
            savings, fast processing, and safety as you deposit and trade in
            your preferred digital assets."
          </p>
        </div>
        <div>
          <img class="Vector2" src="./assets/imgs/Vector2.png" alt="sdsadsad" />
        </div>
        <div>
          <img class="tangle3" src="./assets/imgs/tangle3.png" alt="sdsadsad" />
          <h3 class="Podcast" style="margin-left: -70px">
            Connect To Exchange
          </h3>
          <p class="Podcast-content" style="margin-left: -70px">
            By connecting all of your exchange accounts, "Connect to Exchange"
            streamlines your trading experience by acting as a central centre
            for effective trading and real-time data access. With consolidated
            portfolio management, this tool improves your trading experience
            overall by enabling you to make well-informed decisions and execute
            trades."
          </p>
        </div>
      </div>
    </div>
    <!-- -------------------- -->
    <div class="imgInSlider">
      <ng-image-slider
        class="subImgSlide"
        #nav
        [images]="imageObject"
        [infinite]="true"
        [autoSlide]="0.1"
        slideImage="10"
      ></ng-image-slider>
    </div>
    <!-- ---------------------- -->

    <div style="margin-top: -50px">
      <h2 class="DBI">Delivery Business Interfaces</h2>
      <hr class="Rectangle3" />
      <div class="example-button-row tableButtonRow">
        <button
          mat-button
          mat-raised-button
          *ngFor="let type of market_types; let i = index"
          [ngClass]="type.activStatus == true ? 'Marketbtn' : 'table-type'"
          matTooltip="{{ type.toolTip }}"
          (click)="changetype(i)"
        >
          {{ type.name }}
        </button>
        <!-- <button mat-button class="TradingBtn">Trending Today</button>
        <button mat-button class="GainerBtn">Today’s Gainers</button> -->
      </div>
      <div class="market-window web-view">
        <div class="flex-window">
          <div class="row table-functions">
            <p class="market">Market</p>
            <div class="searchBox">
              <!-- <input class="searchInput" placeholder="Find Currency." matInput (keyup)="applyFilter($event)">
              <img src="./assets/imgs/search.svg" alt="" class="searchimg" /> -->
            </div>
            <div class="filter">
              <!-- <mat-icon class="filterIcon">filter_list</mat-icon> -->
            </div>
          </div>
          <div *ngIf="tableLoader">
            <table
              mat-table
              [dataSource]="[]"
              multiTemplateDataRows
              class="mat-elevation-z8 marketTable"
              style="border-radius: 0px 0px 25px 25px !important"
            >
              <ng-container matColumnDef="logo">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="justify-content: center; align-items: center"
                >
                  <img [src]="element.logo" height="20" width="20" />
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Coin</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="buy">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="text-align-last: end"
                >
                  Buy
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="text-align-last: end"
                >
                  {{ element.bidPrice | number : "1.2-2" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="sell">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="text-align-last: end"
                >
                  Sell
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="text-align-last: end"
                >
                  {{ element.openPrice | number : "1.2-2" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="MarketCap">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="text-align-last: end"
                >
                  Market Cap
                  <mat-icon
                    class="icon"
                    matTooltip="The total market value of a cryptocurrency's circulating supply. It is analogous to the free-float capitalization in the stock market.
                    Market Cap = Current Price x Circulating Supply."
                    >info</mat-icon
                  >
                </th>
                <td mat-cell *matCellDef="let element" class="tableRow">
                  {{ element.marketCapPrice }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Volume">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="text-align-last: end"
                >
                  Volume(24h)
                  <mat-icon
                    style="margin-top: -15px"
                    class="icons"
                    matTooltip="A measure of how much of a cryptocurrency was traded in the last 24 hours."
                    >info</mat-icon
                  >
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="text-align-last: end; padding-right: 30px !important"
                >
                  {{ element.volume | number : "1.2-2" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="text-align-last: end"
                ></th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="text-align-last: end"
                >
                  <button mat-button class="tradeBtn" (click)="trade(element)">
                    Trade
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="expand">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  aria-label="row actions"
                  style="width: 30px !important"
                >
                  &nbsp;
                </th>
                <td mat-cell *matCellDef="let element">
                  <button
                    mat-icon-button
                    aria-label="expand row"
                    (click)="
                      expandedElement =
                        expandedElement === element ? null : element;
                      $event.stopPropagation()
                    "
                  >
                    <mat-icon
                      *ngIf="expandedElement !== element"
                      (click)="myGraphData(element)"
                      >keyboard_arrow_down</mat-icon
                    >
                    <mat-icon *ngIf="expandedElement === element"
                      >keyboard_arrow_up</mat-icon
                    >
                  </button>
                </td>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="columnsToDisplayWithExpand"
              ></tr>
              <tr
                mat-row
                *matRowDef="let element; columns: columnsToDisplayWithExpand"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="$event.stopPropagation()"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: ['expandedDetail']"
                class="example-detail-row"
              ></tr>
            </table>
            <table-loader [rowData]="false"></table-loader>
          </div>

          <div class="matTable" *ngIf="!tableLoader">
            <table
              mat-table
              [dataSource]="dataSource"
              multiTemplateDataRows
              class="mat-elevation-z8 marketTable"
              style="border-radius: 0px 0px 25px 25px !important"
            >
              <ng-container matColumnDef="logo">
                <th mat-header-cell *matHeaderCellDef></th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="justify-content: center; align-items: center"
                >
                  <img [src]="element.logo" height="20" width="20" />
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Coin</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.name }}
                </td>
              </ng-container>

              <ng-container matColumnDef="buy">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="text-align-last: end"
                >
                  Buy
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="text-align-last: end"
                >
                  {{ element.bidPrice | number : "1.2-2" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="sell">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="text-align-last: end"
                >
                  Sell
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="text-align-last: end"
                >
                  {{ element.openPrice | number : "1.2-2" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="MarketCap">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="text-align-last: end"
                >
                  Market Cap
                  <mat-icon
                    class="icon"
                    matTooltip="The total market value of a cryptocurrency's circulating supply. It is analogous to the free-float capitalization in the stock market.
                      Market Cap = Current Price x Circulating Supply."
                    >info</mat-icon
                  >
                </th>
                <td mat-cell *matCellDef="let element" class="tableRow">
                  {{ element.marketCapPrice }}
                </td>
              </ng-container>

              <ng-container matColumnDef="Volume">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="text-align-last: end"
                >
                  Volume(24h)
                  <mat-icon
                    style="margin-top: -15px"
                    class="icons"
                    matTooltip="A measure of how much of a cryptocurrency was traded in the last 24 hours."
                    >info</mat-icon
                  >
                </th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="text-align-last: end; padding-right: 30px !important"
                >
                  {{ element.volume | number : "1.2-2" }}
                </td>
              </ng-container>

              <ng-container matColumnDef="action">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  style="text-align-last: end"
                ></th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  style="text-align-last: end"
                >
                  <button mat-button class="tradeBtn" (click)="trade(element)">
                    Trade
                  </button>
                </td>
              </ng-container>

              <ng-container matColumnDef="expand">
                <th
                  mat-header-cell
                  *matHeaderCellDef
                  aria-label="row actions"
                  style="width: 30px !important"
                >
                  &nbsp;
                </th>
                <td mat-cell *matCellDef="let element">
                  <button
                    mat-icon-button
                    aria-label="expand row"
                    (click)="
                      expandedElement =
                        expandedElement === element ? null : element;
                      $event.stopPropagation()
                    "
                  >
                    <mat-icon
                      *ngIf="expandedElement !== element"
                      (click)="myGraphData(element)"
                      >keyboard_arrow_down</mat-icon
                    >
                    <mat-icon *ngIf="expandedElement === element"
                      >keyboard_arrow_up</mat-icon
                    >
                  </button>
                </td>
              </ng-container>

              <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
              <ng-container matColumnDef="expandedDetail">
                <span>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    [attr.colspan]="columnsToDisplayWithExpand.length"
                  >
                    <table-loader
                      [rowData]="true"
                      *ngIf="dataLoader"
                    ></table-loader>
                    <div
                      class="example-element-detail"
                      [@detailExpand]="
                        element == expandedElement ? 'expanded' : 'collapsed'
                      "
                      *ngIf="!dataLoader"
                    >
                      <div class="expanded-container">
                        <div>
                          <span style="font-weight: 500"> Price: </span>${{
                            element.currentPrice | number : "1.2-2"
                          }}
                        </div>
                        <div
                          style="
                            text-align-last: end;
                            justify-content: center;
                            align-items: center;
                            display: flex;
                          "
                        >
                          <span style="font-weight: 500">(24h%):</span>
                          <span
                            ><mat-icon
                              style="color: #f04444"
                              *ngIf="element.priceChangePercent < 0"
                              >arrow_drop_down</mat-icon
                            >
                            <mat-icon
                              style="color: #4fc280"
                              *ngIf="element.priceChangePercent > 0"
                              >arrow_drop_up</mat-icon
                            ></span
                          >

                          <span
                            [ngClass]="
                              element.priceChangePercent > 0
                                ? 'profit_class'
                                : 'loss_class'
                            "
                            >{{
                              element.priceChangePercent | number : "1.2-2"
                            }}%</span
                          >
                        </div>
                        <div
                          style="
                            text-align-last: end;

                            justify-content: center;
                            align-items: center;
                            display: flex;
                          "
                        >
                          <span style="font-weight: 500">(7d %):</span>
                          <span>
                            <mat-icon
                              style="color: #f04444"
                              *ngIf="element.weekChangePercent < 0"
                              >arrow_drop_down</mat-icon
                            >
                            <mat-icon
                              style="color: #4fc280"
                              *ngIf="element.weekChangePercent > 0"
                              >arrow_drop_up</mat-icon
                            >
                          </span>

                          <span
                            [ngClass]="
                              element.weekChangePercent > 0
                                ? 'profit_class'
                                : 'loss_class'
                            "
                            >{{
                              element.weekChangePercent | number : "1.2-2"
                            }}%</span
                          >
                        </div>
                        <div style="height: 100px; width: 150px">
                          <cc-line-graph
                            [showX]="false"
                            [showY]="false"
                            [data]="graphdata"
                            [color]="color"
                          ></cc-line-graph>
                        </div>
                      </div>
                    </div>
                  </td>
                </span>
              </ng-container>

              <tr
                mat-header-row
                *matHeaderRowDef="columnsToDisplayWithExpand"
              ></tr>
              <tr
                mat-row
                *matRowDef="let element; columns: columnsToDisplayWithExpand"
                class="example-element-row"
                [class.example-expanded-row]="expandedElement === element"
                (click)="$event.stopPropagation()"
              ></tr>
              <tr
                mat-row
                *matRowDef="let row; columns: ['expandedDetail']"
                class="example-detail-row"
              ></tr>
            </table>
            <div style="background-color: white;width: 100%;height: 20px;border-bottom-left-radius: 30px;border-bottom-right-radius: 30px;"></div>
            <!-- <mat-paginator [pageSizeOptions]="[10, 25, 50]" aria-label="Coin List"></mat-paginator> -->
            <div style="margin-top: 20px">
              <button
                mat-raised-button
                class="viewBtn"
                (click)="to_market()"
                routerLinkActive="activebutton"
              >
                View more<mat-icon style="margin-left: 10px"
                  >arrow_right_alt</mat-icon
                >
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- after table================================================ -->
    <div class="howwedo-container howvDo">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div fxFlex="50" style="margin-left: 15%">
          <mat-list>
            <p
              style="
                font-size: 32px;
                margin: 0px 0px 10% 14%;
                font-family: cc-highlightFont;
              "
            >
              How We Do It?
            </p>
            <mat-list-item *ngFor="let feature of featuresList">
              <mat-spinner
                diameter="30"
                mode="determinate"
                [value]="spinnerValue"
                strokeWidth="4"
                *ngIf="feature.active"
              ></mat-spinner>
              <img
                src="./../../assets/images/icons/Ellipse 964.png"
                *ngIf="!feature.active"
                height="29px"
                width="29px"
              />
              <div matListItemTitle style="margin-left: 5%">
                <span
                  style="font-size: 20px"
                  [ngClass]="
                    feature.active == true ? 'mediumFont' : 'regularFont'
                  "
                  >{{ feature.name }}</span
                >
              </div>
            </mat-list-item>
          </mat-list>
        </div>
        <div class="wrapper" fxFlex="50">
          <span *ngFor="let feature of featuresList">
            <img
              [src]="feature.banner"
              class="fetures"
              *ngIf="feature.active"
            />
          </span>
        </div>
      </div>
    </div>
    <!-- ========================================== -->
    <div class="DldApp">
      <div fxLayout="row" fxLayoutAlign="space-around center">
        <div style="margin-left: 5%">
          <h2 class="class3">Download our App</h2>
          <p class="para1">
            Sync your data between your desktop and mobile app and keep track of
            your crypto assets no matter where you are
          </p>
          <img
            class="appStore"
            src="./assets/imgs/appStore1.png"
            alt="appleLogo"
          />
          <img
            class="appStore"
            src="./assets/imgs/appStore2.png"
            alt="appleLogo"
          />
        </div>
        <div class="iphnPack">
          <img class="iphone" src="./assets/imgs/iphone1.png" alt="iphone" />
          <img class="Macbook" src="./assets/imgs/Macbook1.png" alt="macbook" />
        </div>
      </div>
    </div>
    <!-- ================================================= -->
    <div class="myFAQ">
      <img class="qtnImg" src="./assets/imgs/question.svg" alt="" />
      <h3 class="Frequent">Frequently Asked Questions</h3>
      <hr class="Rectangle4" style="margin-bottom: 30px" />

      <div
        class="FrequentQuestions"
        *ngFor="let faq of myFaq; let i = index"
        (click)="getAnswer(i)"
      >
        <mat-accordion style="box-shadow: none !important">
          <mat-expansion-panel style="margin-top: 1px">
            <mat-expansion-panel-header>
              <mat-panel-title
                class="panelTitle"
                (opened)="panelOpenState = true"
                (closed)="panelOpenState = false"
                [class.panelOpen]="panelOpenState"
              >
                {{ faq.question }}
              </mat-panel-title>
              <mat-panel-description> </mat-panel-description>
            </mat-expansion-panel-header>
            <p class="panelDescription">
              {{ faq.answer }}
            </p>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
    <!-- ------------------- -->
  </div>
</div>
