<span style="display: flex; justify-content: center">
  <img
    *ngIf="data.type == 'succuss'"
    src="./../../assets/images/icons/checked 1.png"
    height="100"
    width="100"
  />
  <img
    *ngIf="data.type == 'points'"
    src="./../../assets/images/icons/diamond 1.png"
    height="100"
    width="100"
  />
</span>
<br />
<p style="text-align: center; font-size: 18px">{{ data.message }}</p>
<span class="description" *ngIf="data.description != ' '">{{ data.description }}</span>
