import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// _layout components
import { HomeLayoutComponent } from './_layout/home-layout/home-layout.component';
import { SiteLayoutComponent } from './_layout/site-layout/site-layout.component';
import { HomeHeaderComponent } from './_layout/home-header/home-header.component';
import { HomeFooterComponent } from './_layout/home-footer/home-footer.component';
import { SiteFooterComponent } from './_layout/site-footer/site-footer.component';
import { SiteHeaderComponent } from './_layout/site-header/site-header.component';
// _layout components Ends

import { SharedComponents } from 'src/_sharedComponents/sharedComponents.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgImageSliderModule } from 'ng-image-slider';

//Auth
import { AuthInterceptor, tokenInterceptor } from './auth.interceptor';
import { LocalStorageProvider } from 'src/providers/local-storage.provider';
import { AuthService } from 'src/providers/http/auth.service';

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    SiteLayoutComponent,
    HomeHeaderComponent,
    HomeFooterComponent,
    SiteFooterComponent,
    SiteHeaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedComponents,
    NgImageSliderModule,
  ],
  providers: [
    LocalStorageProvider,
    AuthService,
    tokenInterceptor
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
