import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AppEndPoints } from 'src/config/end-points.constants';
import { HeadersConstants } from 'src/config/headers.constants';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { NumberFormatterCallbackFunction } from 'highcharts';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  // private tabClickSubject = new BehaviorSubject<boolean>(false);
  private statusSource = new BehaviorSubject(false);
  private status = new BehaviorSubject(false);
  private ctstatus = new BehaviorSubject(false);
  currentStatus = this.statusSource.asObservable();
  tabClick$ = this.status.asObservable();
  ctTabClick$ = this.ctstatus.asObservable();
  tradingdesk$: Observable<any>;
  balanceOb$: Observable<any>;
  copyTrader$: Observable<any>;
  marketCoin$: Observable<any>;
  orderFilter$: Observable<any>;
  currentTab$: Observable<any>;
  currentTrader$: Observable<any>;
  tradedPairs$: Observable<any>;
  private socket$: WebSocketSubject<any>;
  private tickerSubscription: Subscription;

  
  profileUpdate$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private tradingDeskSubject = new BehaviorSubject<any>({});
  private balanceSubject = new BehaviorSubject<number>(0);
  private copyTraderSubject = new BehaviorSubject<any>({});
  private marketTradeSubject = new BehaviorSubject<any>('BTC');
  private orderTableFilterSubject = new BehaviorSubject<any>({});
  private securityType = new BehaviorSubject<any>({});
  private selectedTraderSubject = new BehaviorSubject<any>(null);
  sType$: Observable<any>;
  private currentTabSubject = new BehaviorSubject<any>(0);
  
  private checkProfilePath = new BehaviorSubject<boolean>(false);
  activePath$ = this.checkProfilePath.asObservable();

  private ProfilePath = new BehaviorSubject<boolean>(false);
  activeProPath$ = this.ProfilePath.asObservable();

  private ProfilePathApi = new BehaviorSubject<boolean>(false);
  activeApiPath$ = this.ProfilePathApi.asObservable();

  private component = new BehaviorSubject<any>(null);
  activecompo$ = this.component.asObservable();

  private OrdersLengthData = new BehaviorSubject<any>(0);
  ordersLength$ = this.OrdersLengthData.asObservable()


  constructor(public http: HttpClient) {
    this.tradingdesk$ = this.tradingDeskSubject.asObservable();
    this.balanceOb$ = this.balanceSubject.asObservable();
    this.copyTrader$ = this.copyTraderSubject.asObservable();
    this.marketCoin$ = this.marketTradeSubject.asObservable();
    this.orderFilter$ = this.orderTableFilterSubject.asObservable();
    this.sType$ = this.securityType.asObservable();
    this.currentTab$ = this.currentTabSubject.asObservable();
    this.currentTrader$ = this.selectedTraderSubject.asObservable();
  }

  setOrdersLength(data:any){
    this.OrdersLengthData.next(data)
  }

  setTabClick(value: boolean) {
    this.status.next(value);
  }

  setCTTabClick(value: boolean) {
    this.ctstatus.next(value);
  }

  getTickerUpdates(symbol: string) {
    let url = 'wss://stream.binance.com:9443/ws/' + symbol + '@ticker';
    this.socket$ = webSocket(url);
    return this.socket$;
  }

  changeMessage(status: boolean) {
    this.status.next(status);
  }

  getExchanges() {
    let url = AppEndPoints.GETBROKERS;
    return this.http.get(url, HeadersConstants.tockenHeader);
  }

  dataToWidget(data: any) {
    this.tradingDeskSubject.next(data);
  }

  balance(balance: any) {
    this.balanceSubject.next(balance);
  }

  getCopyTraderDetails(data: any) {
    this.copyTraderSubject.next(data);
  }

  getMarketTradeCoin(data: any) {
    this.marketTradeSubject.next(data);
  }

  getorderTableFilter(data: any) {
    this.orderTableFilterSubject.next(data);
  }

  ngOnDestroy() {
    this.socket$.unsubscribe();
  }

  getPictureUpdate(): Observable<boolean> {
    return this.profileUpdate$.asObservable();
  }

  setPictureUpdate(status: any) {
    this.profileUpdate$.next(status);
  }

  dataToSecurity(value: any) {
    this.securityType.next(value);
  }

  setState(tabIndex: number) {
    this.currentTabSubject.next(tabIndex);
  }

  setTrader(id: string) {
    this.selectedTraderSubject.next(id);
  }

  setProfileFlow(active: boolean){
    this.checkProfilePath.next(active)
  }

  checkProfileFlow(active: boolean){
    this.ProfilePath.next(active)
  }

  setProfileFlowApi(active: boolean){
    this.ProfilePathApi.next(active)
  }

  setComponent(comp: string){
    this.component.next(comp)
  }
}
