import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cc-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit {
  data = [{
    coinName : 'ripple',
    symbol:'xrp',
    price: 20034,
    todaysData: {
      price:23,
      percent:2
    }
  },{
    coinName : 'bitcoin',
    symbol:'btc',
    price: 2003,
    todaysData: {
      price:9,
      percent:2
    }
  },{
    coinName : 'etherium',
    symbol:'eth',
    price: 2034,
    todaysData: {
      price:2,
      percent:20
    }
  }];
  constructor() {}

  ngOnInit(): void {}
}
