<div class="mainContainer">
  <div class="inner-continer">
    <span *ngFor="let d of data; let i = index">
      <mat-card [ngClass]="{ even_card: i % 2 == 0, odd_card: i % 2 != 0 }">
        <img src="../assets/images/coin_logo/{{d.symbol}}.png" class="cLogo" alt="adsd"/>

        <mat-card-header>
          <div mat-card-avatar class="header-image"></div>
          <mat-card-title class="cName">{{ d.coinName | uppercase }}</mat-card-title>
          
          <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
        </mat-card-header>
        <mat-card-content>
          <span class="card-content">
            <div>
              <span class="price">$ {{ d.price }}</span>
            </div>
            <div class="spacer">
              <!-- <cc-line-graph></cc-line-graph> -->
            </div>
          </span>
          <span class="card-content">
              <div class="today">today</div>
              <div>
                <span class="todayPrice">${{ d.todaysData.price }}</span>
                <span class="dataPercent"><button class="dataPercent1" mat-button>{{ d.todaysData.percent }}%</button></span>
              </div>
          </span>
        </mat-card-content>
      </mat-card>
    </span>
  </div>
</div>
