interface MyObject {
  [key: string]: string;
}
// const api_root = 'https://min-api.cryptocompare.com';
const api_root = 'https://api.binance.com/api/v3/klines?';
const iffl_base_url =
  'https://tradingdeskstocks.cryptocentric.co/api/v1.0/trading/iifl/getHistoricalData?uniqueid=2051710307703061';
const history: MyObject = { key: 'value' };

function formatParams(params: any) {
  return (
    '?' +
    Object.keys(params)
      .map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&')
  );
}

function getRequest(url: string): Promise<any> {
  return new Promise<any>(function (resolve, reject) {
    const request = new XMLHttpRequest();
    request.onload = function () {
      if (this.status === 200) {
        resolve(this.response);
      } else {
        reject(new Error(this.statusText));
      }
    };
    request.onerror = function () {
      reject(new Error('XMLHttpRequest Error: ' + this.statusText));
    };
    request.open('GET', `${url}`);
    request.send();
  });
}

// export default {
//   history: history,
//   getBars: (symbolInfo:any, resolution:any, from:any ,to:any, first:any, limit:any) => {
//     const split_symbol = symbolInfo.name.split('/');
//     let url : String
//     if(resolution === '60'){
//       url = 'interval=1h'
//     }
//     else if(resolution === '1'){
//       url ='interval=1m'
//     }
//     else if(resolution === 'D' || '1D'){
//       url = 'interval=1d'
//     }
//    return getRequest(`${api_root}symbol=${split_symbol[0]}${split_symbol[1]}&${url}&limit=2000`)
//       .then(d => {
//         const data = JSON.parse(d);
//         if (data.Response && data.Response === 'Error') {
//           return [];
//         }
//         if (data.length) {
//           const bars = data.map((el:any) => {
//             return {
//               time : el[0],
//               low:el[3] * 1.52423798503,
//               high:el[2] * 1.52423798503,
//               open:el[1] * 1.52423798503,
//               close:el[4] * 1.52423798503,
//               volume:el[5] * 1.52423798503
//             }
//           });
//           if (first) {
//             const lastBar = bars[bars.length - 1];
//           }
//           return bars;
//         } else {
//           return [];
//         }
//       })
//       .catch(error => {
//         console.log(error);
//       });
//   }
// };
function getIIFLscriptCode(url: string): Promise<any> {
  return new Promise<any>(function (resolve, reject) {
    const request = new XMLHttpRequest();
    request.onload = function () {
      if (this.status === 200) {
        resolve(this.response);
      } else {
        reject(new Error(this.statusText));
      }
    };
    request.onerror = function () {
      reject(new Error('XMLHttpRequest Error: ' + this.statusText));
    };
    request.open('GET', `${url}`);
    request.send();
  });
}

export default {
  history: history,
  getBars: (
    symbolInfo: any,
    resolution: any,
    from: any,
    to: any,
    first: any,
    limit: any
  ) => {
    const split_symbol = symbolInfo.name;
    let url: String;
    if (resolution === '60') {
      url = 'interval=1h';
    } else if (resolution === '1') {
      url = 'interval=1m';
    } else if (resolution === 'D' || resolution === '1D') {
      url = 'interval=1d';
    }
    let bars: any[] = [];
    return getIIFLscriptCode(
      'https://tradingdeskstocks.cryptocentric.co/api/v1.0/trading/iifl/getSymbolDetails?symbol=' +
        split_symbol
    )
      .then((data) => {
        let d = JSON.parse(data);
        if (d.status == 'success') {
          let stockData = d.data.symbolData.filter(
            (ele: any) => ele.Exch == 'N'
          );
          if (stockData.length == 0) {
            alert('This stock is not available in NSE');
            return [];
          } else {
            let today = new Date();
            let oneYearago = new Date(
              today.getFullYear() - 1,
              today.getMonth(),
              today.getDate()
            )
              .toISOString()
              .slice(0, 10);
            let today_ = new Date().toISOString().slice(0, 10);
            return getRequest(
              iffl_base_url +
                '&Exch=' +
                stockData[0].Exch +
                '&ExchType=' +
                stockData[0].ExchType +
                '&ScripCode=' +
                stockData[0].Scripcode +
                '&Interval=1d&FromDate=' +
                oneYearago +
                '&EndDate=' +
                today_
            ).then((d) => {
              const data = JSON.parse(d);
              if (data.status == 'success') {
                const bars = data.data.candleData.map((el: any) => {
                  return {
                    time: new Date(el[0]),
                    low: el[3],
                    high: el[2],
                    open: el[1],
                    close: el[4],
                    volume: el[5],
                  };
                });
                if (first) {
                  const lastBar = bars[bars.length - 1];
                }
                return bars;
              }
            });
          }
        } else {
          return [];
        }
      })
      .catch((error) => {
        console.log(error);
        return bars;
      });
  },
};
