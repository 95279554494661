import { Component, OnInit, ViewChild } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { interval, Subscription } from 'rxjs';
import { MarketService } from 'src/providers/http/market.service';
import { SnackbarService } from 'src/providers/http/snack-bar.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { SharedService } from 'src/providers/http/shared.service';
import { LocalStorageProvider } from 'src/providers/local-storage.provider';

@Component({
  selector: 'cc-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      ),
    ]),
  ],
})
export class HomePageComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  columnsToDisplay = [
    // 'position',
    'logo',
    'name',
    'buy',
    'sell',
    'MarketCap',
    'Volume',
    'action',
  ];
  columnsToDisplayWithExpand = [...this.columnsToDisplay, 'expand'];
  expandedElement: any;
  imagesUrl: any;
  myPhone: any;
  image1: any;
  image2: any;
  image3: any;
  value = 50;
  panelOpenState = false;
  typeSelected: string;
  imagesUrls: any;
  subscription: Subscription;
  i = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  featuresList = [
    {
      name: 'Aggregator',
      active: true,
      banner: './../../assets/images/Group 12611527901.png',
    },
    {
      name: 'Trading desk',
      active: false,
      banner: './../../assets/images/Component_61.png',
    },
    {
      name: 'Copy trader',
      active: false,
      banner: './../../assets/images/Component_141.png',
    },
  ];
  index = 0;
  spinnerValue = 0;
  coinData: Object;
  graphdata: any;
  grapData: any = [];
  tableLoader: boolean = true;
  dataLoader: boolean = false;
  color: string;
  marketCapData: any[];
  myIndex: number = 0;
  market_types: any[] = [
    {
      name: 'Market Cap',
      activStatus: true,
      toolTip: 'Market Cap Values'
    },
    {
      name: 'Trending Today',
      activStatus: false,
      toolTip: 'Coins which are trending today'
    },
    {
      name: 'Today`s Gainers',
      activStatus: false,
      toolTip: 'Coins proffitable in 24 hrs'
    },

  ];
  userId: any;
  isLogIn: any;
  constructor(
    private _market: MarketService,
    private _snackbar: SnackbarService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private _shared: SharedService,
    private _storageProvider: LocalStorageProvider
  ) {
    this.typeSelected = 'ball-fussion';
  }

  ngOnInit(): void {
    this.imagesUrl = [
      './assets/imgs/binance1.svg',
      ,
      './assets/imgs/IIFL.svg',
      './assets/imgs/zeroda.svg',
    ];
    this.imagesUrls = [
      './assets/imgs/binance.svg',
      ,
      './assets/imgs/IBrokers.svg',
      './assets/imgs/zeroda.svg',
    ];
    const source = interval(60000);
    const timmer = interval(1000);
    this.subscription = timmer.subscribe((val) => this.change());
    this.getCoinData();
    this.isLogIn = this._storageProvider.getAuthUser().isLoggedIn;
    this.userId = this._storageProvider.getLoggedInUserInfo().userId;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  changetype(i: number) {
    if (i == 2) {
      let dataSource = this.marketCapData.filter(coin => coin.priceChangePercent >= 0);
      this.dataSource = new MatTableDataSource(dataSource);
    }
    else if (i == 3) {
      let dataSource = this.marketCapData.filter(coin => coin.priceChangePercent < 0);
      this.dataSource = new MatTableDataSource(dataSource);
    }
    else {
      this.dataSource = new MatTableDataSource(this.marketCapData);
    }
    this.dataSource.paginator = this.paginator;
    this.market_types.forEach((ele, index) => {
      if (index == i) {
        ele.activStatus = true;
      }
      else {
        ele.activStatus = false;
      }
    })
  }

  to_market() {
    if (localStorage.getItem('authUser') != null) {
      this.router.navigate(['/market/' + this.userId + '/market-overview/']);
    } else if (localStorage.getItem('authUser') == null) {
      this.router.navigate(['/market/market-overview']);
    }
  }

  getCoinData() {
    this.dataSource = new MatTableDataSource([]);
    this.tableLoader = true;
    let dataSource;
    this._market.getMarketData().subscribe({
      next: (res: any) => {
        if (res.status == 'success') {
          dataSource = res.data.marketData.slice(0, 10);
          this.marketCapData = res.data.marketData.slice(0, 10);
          dataSource.forEach((ele: any) => {
            Object.assign(ele, { logo: '', weekChangePercent: 0 });
            this._market.getLogo(ele.baseAsset).subscribe({
              next: (res: any) => {
                if (res.status == 'success') {
                  ele.logo = this._sanitizer.bypassSecurityTrustResourceUrl(
                    'data:image/jpg;base64,' + res.data.logoBase64Data
                  );
                }
                this.dataSource.paginator = this.paginator;
              },
              error: (err: any) => {
                this._snackbar.error(err.error.data.message);
              },
            });
          });
          this.dataSource = new MatTableDataSource(dataSource);
          this.tableLoader = false;
        } else {
          dataSource = [];
        }
      },
      error: (err: any) => {
        this._snackbar.error(err.error.data.message);
      },
    });
  }
  // getCoinData() {
  //   this.tableLoader = true;
  //   let dataSource;
  //   this._market.getMarketData().subscribe({
  //     next: (res: any) => {
  //       console.log(res,'coin data')
  //       if (res.status == 'success') {
  //         dataSource = res.data.marketData.slice(0,10);
  //         console.log(dataSource,'dataSource')
  //         dataSource.forEach((ele: any) => {
  //           Object.assign(ele, { logo: '', weekChangePercent: 0 });
  //           this._market.getLogo(ele.baseAsset).subscribe({
  //             next: (res: any) => {
  //               if (res.status == 'success') {
  //                 ele.logo = this._sanitizer.bypassSecurityTrustResourceUrl(
  //                   'data:image/jpg;base64,' + res.data.logoBase64Data
  //                 );
  //               }
  //               this.dataSource.paginator = this.paginator;
  //             },
  //             error: (err: any) => {
  //               this._snackbar.error(err.error.data.message);
  //             },
  //           });
  //         });
  //         this.dataSource = new MatTableDataSource(dataSource);
  //         this.tableLoader = false;
  //       } else {
  //         dataSource = [];
  //       }
  //     },
  //     error: (err: any) => {
  //       this._snackbar.error(err.error.data.message);
  //     },
  //   });
  // }

  imageObject = [

    {
      posterImage: './assets/imgs/MicrosoftTeams-image (21).png',
    },
    {
      posterImage: './assets/imgs/MicrosoftTeams-image (22).png',
    },
    {
      posterImage: './assets/imgs/MicrosoftTeams-image (23).png',
    },
    {
      posterImage: './assets/imgs/MicrosoftTeams-image (24).png',
    },
    {
      posterImage: './assets/imgs/MicrosoftTeams-image (25).png',
    },
    {
      posterImage: './assets/imgs/MicrosoftTeams-image (26).png',
    },
    {
      posterImage: './assets/imgs/MicrosoftTeams-image (22).png',
    },
    {
      posterImage: './assets/imgs/MicrosoftTeams-image (23).png',
    },
    {
      posterImage: './assets/imgs/MicrosoftTeams-image (24).png',
    },
  ];

  imageObject1 = [
    {
      posterImage: './assets/imgs/pana.png',
    },
    {
      posterImage: './assets/imgs/IIFL.svg',
    },
    {
      posterImage: './assets/imgs/Bittrex.svg',
    },
  ];

  myFaq = [
    {
      question: ' What is Coin Centric?',
      id: 1,
      answer: ' Coin Centric is a comprehensive trading aggregator that allows users to access, manage, and trade cryptocurrencies and stocks from various exchanges, all within a single, user-friendly platform.'
    },
    {
      question: ' How can I get started with Coin Centric?',
      id: 2,
      answer: 'To get started, simply sign up for an account on our platform and follow the instructions to connect your exchange accounts and explore the features we offer for a streamlined trading experience.'
    },
    {
      question: ' How do I connect my exchange accounts to Coin Centric?',
      id: 3,
      answer: 'To connect your exchange accounts, log in to your Coin Centric account, navigate to the "Connect to Exchange" section, and follow the instructions for linking your exchange accounts. You may need to provide API keys for authentication.'
    },
    {
      question: ' Which exchanges can I connect to Coin Centric?',
      id: 4,
      answer: 'Coin Centric offers compatibility with a wide range of cryptocurrency and stock exchanges, including [List of Supported Exchanges]. You can connect to multiple exchanges simultaneously for a unified trading experience.'
    },
    {
      question: 'Is my data and trading information secure when using Coin Centric?',
      id: 5,
      answer: 'Yes, we prioritize the security of your data and trading activities. We implement industry-standard security measures and encryption to safeguard your information and assets.'
    },
    {
      question: 'What are the benefits of using the "Connect to Exchange" feature?',
      id: 6,
      answer: 'By connecting your exchange accounts, you can access a unified dashboard for efficient trading execution, real-time data updates, and streamlined portfolio management. This feature simplifies your trading operations and enhances your decision-making capabilities.'
    },
    {
      question: 'Can I trade directly from Coin Centric after connecting my exchange accounts?',
      id: 7,
      answer: 'Yes, after connecting your exchange accounts, you can execute trades directly from our platform, reducing the need to switch between multiple exchange accounts for trading.'
    },
    {
      question: ' How can I monitor my portfolio and asset performance after linking my exchange accounts?',
      id: 8,
      answer: "You can easily monitor your portfolio's performance and asset values across various exchanges from your unified Coin Centric dashboard, giving you a comprehensive overview of your investments."
    },
  ]
  getAnswer(index: number) {
    this.myIndex = index
    // this.answer = myFaq[index].answer;
  }
  changeBanner(index: number) {
    this.index = this.index + 1;
    if (index < this.featuresList.length) {
      this.featuresList.forEach((item) => {
        if (this.featuresList.indexOf(item) == index) {
          item.active = true;
        } else {
          item.active = false;
        }
      });
    } else {
      this.index = 0;
    }
  }

  change() {
    this.spinnerValue = this.spinnerValue + 25;
    if (this.spinnerValue == 100) {
      this.spinnerValue = 0;
      this.changeBanner(this.index);
    }
  }

  test() {
    this.i = this.i + 1;
  }


  trade(coinData: any) {
    this._shared.getMarketTradeCoin(coinData.baseAsset);
    if (localStorage.getItem('authUser') != null) {
      this.router.navigate(['/market/' + this.userId]);
    } else if (localStorage.getItem('authUser') == null) {
      this.router.navigate(['/login']);
    }
  }
  // applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  // }

  myGraphData(element: any) {
    this.grapData = [];
    this._market.getGraphData(element.symbol).subscribe({
      next: (res: any) => {
        if (res.status == 'success') {
          this.dataLoader = true;
          res.data.graphData.forEach((item: any) => {
            this.grapData.push(Number(item[1]));
          });
          if (res.data.weekChange > 0) {
            this.color = 'green';
          } else {
            this.color = 'red';
          }
          this.graphdata = this.grapData;
          element.weekChangePercent =
            ((this.graphdata[this.graphdata.length - 1] - this.graphdata[0]) /
              this.graphdata[0]) *
            100;
          this.dataLoader = false;
        }
      },
      error: (err: any) => {
        this._snackbar.error(err.error.message);
      },
    });
  }

  startTrading(){
    this.router.navigate(['/sign_up']);
  }

}
