import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/providers/http/auth.service';
import { SharedService } from 'src/providers/http/shared.service';
import { SnackbarService } from 'src/providers/http/snack-bar.service';
import { LocalStorageProvider } from 'src/providers/local-storage.provider';

@Component({
  selector: 'app-security-questions',
  templateUrl: './security-questions.component.html',
  styleUrls: ['./security-questions.component.scss']
})
export class SecurityQuestionsComponent implements OnInit {
  secQuestionsList: any;
  firstQuestionIndex: any;
  loginUser: any;
  selectedTyepe: any;

  constructor(private fb: FormBuilder,
    public auth: AuthService,
    private _localStorageProvider: LocalStorageProvider,
    private _snackbar: SnackbarService,
    private _shared: SharedService,
    private router: Router,
  ) { }
  SecurityQuestions!: FormGroup;
  ngOnInit(): void {

    this._shared.sType$.subscribe((value: any) => {
      this.selectedTyepe = value
    });
    this.loginUser = this._localStorageProvider.getLoggedInUserInfo();
    this.SecurityQuestions = this.fb.group(
      {
        question1: ['', Validators.required],
        question2: ['', Validators.required],
        answer1: ['', Validators.required],
        answer2: ['', Validators.required],

      },
    );
    this.mySecurityQuestions();
  }

  mySecurityQuestions() {
    let min: number = 0
    let max: number;
    let filteredsecurityQuestions = {
      question: '',
      answer: ''
    }
    let securityQuestions: any[] = []
    let question: any
    // let secQuestionsList
    this.auth.getSecurityQuestions().subscribe({
      next: (res: any) => {
        max = res.data.securityQuestions.length;
        let randIndex = Math.round(Math.random() * (max - 1 - min) + min);
        this.secQuestionsList = res.data.securityQuestions;
        // this._snackbar.success(res.data.message);
        // question = secQuestionsList[randIndex];
        // securityQuestions.push(question);
      },
      error: (err: any) => {
        this._snackbar.error(err.error.data.message);
      },
    });
  }

  firstQuestion(firstIndex: number) {
    this.firstQuestionIndex = firstIndex;
  }

  saveQuestions(SecurityQuestions: any) {

    const data = {
      userId: this.loginUser.userId,
      securityQuestions: [
        {
          "question": SecurityQuestions.question1,
          "answer": SecurityQuestions.answer1
        },
        {
          "question": SecurityQuestions.question2,
          "answer": SecurityQuestions.answer2
        }
      ]
    }

    this.auth.saveMyAnswers(data).subscribe({
      next: (res: any) => {
        if (res.status == "success") {
          this._snackbar.success(res.data.message);
          if (this.selectedTyepe == 'SctMFA'|| 'KYC') {
            this.router.navigate(['/authentication']);
          }
        }
      },
      error: (err: any) => {
        this._snackbar.error(err.error.data.message);
      },
    })

   
  }



}
