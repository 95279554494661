import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  HostListener,
  SimpleChanges,
  ElementRef,
} from '@angular/core';
import {
  widget,
  IChartingLibraryWidget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  ResolutionString,
} from './../../assets/charting_library/charting_library.min';
import Datafeed from './api/index';
declare var LightweightCharts: any;

@Component({
  selector: 'tradeWatch-widget',
  templateUrl: './tradingviewwidget.component.html',
  styleUrls: ['./tradingviewwidget.component.scss'],
})
export class TradingviewwidgetComponent implements OnInit, OnDestroy {
  private _symbol: ChartingLibraryWidgetOptions['symbol'] = 'BTC/AUD';
  private _interval: ChartingLibraryWidgetOptions['interval'] =
    'D' as ResolutionString;
  // BEWARE: no trailing slash is expected in feed URL
  private _datafeedUrl = 'https://demo_feed.tradingview.com';
  private _libraryPath: ChartingLibraryWidgetOptions['library_path'] =
    '/assets/charting_library/';
  private _chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'] =
    'https://saveload.tradingview.com';
  private _chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'] =
    '1.1';
  private _clientId: ChartingLibraryWidgetOptions['client_id'] =
    'tradingview.com';
  private _userId: ChartingLibraryWidgetOptions['user_id'] = 'public_user_id';
  private _fullscreen: ChartingLibraryWidgetOptions['fullscreen'] = false;
  private _autosize: ChartingLibraryWidgetOptions['autosize'] = true;
  private _containerId: ChartingLibraryWidgetOptions['container_id'] =
    'tv_chart_container';
  private _tvWidget: IChartingLibraryWidget | null = null;
  studies_overrides: {
    'volume.volume.color.0': string;
    'volume.volume.color.1': string;
    'volume.volume.transparency': number;
    'volume.volume ma.color': string;
    'volume.volume ma.transparency': number;
    'volume.volume ma.linewidth': number;
    'volume.show ma': boolean;
  };

  custom_overrides: {
    volumePaneSize: string;
    'scalesProperties.showLeftScale': boolean;
    'scalesProperties.showRightScale': boolean;
    'mainSeriesProperties.areaStyle.color1': string;
    'mainSeriesProperties.areaStyle.color2': string;
    'mainSeriesProperties.areaStyle.linecolor': string;
    'mainSeriesProperties.areaStyle.linewidth': number;
    'mainSeriesProperties.areaStyle.transparency': number;
    'mainSeriesProperties.candleStyle.upColor': string;
    'mainSeriesProperties.candleStyle.downColor': string;
    'mainSeriesProperties.candleStyle.drawWick': boolean;
    'mainSeriesProperties.candleStyle.drawBorder': boolean;
    'mainSeriesProperties.candleStyle.borderUpColor': string;
    'mainSeriesProperties.candleStyle.borderDownColor': string;
    'mainSeriesProperties.candleStyle.wickUpColor': string;
    'mainSeriesProperties.candleStyle.wickDownColor': string;
    'mainSeriesProperties.candleStyle.barColorsOnPrevClose': boolean;
    'mainSeriesProperties.barStyle.upColor': string;
    'mainSeriesProperties.barStyle.downColor': string;
    'mainSeriesProperties.barStyle.barColorsOnPrevClose': boolean;
    'mainSeriesProperties.barStyle.dontDrawOpen': boolean;
    'mainSeriesProperties.lineStyle.color': string;
    'mainSeriesProperties.lineStyle.linewidth': number;
  };
  myInnerHeight: any;

  widgetOptions: ChartingLibraryWidgetOptions;
  constructor(private el: ElementRef) {}

  @Input()
  set symbol(symbol: ChartingLibraryWidgetOptions['symbol']) {
    this._symbol = symbol || this._symbol;
  }

  @Input()
  set interval(interval: ChartingLibraryWidgetOptions['interval']) {
    this._interval = interval || this._interval;
  }

  @Input()
  set datafeedUrl(datafeedUrl: string) {
    this._datafeedUrl = datafeedUrl || this._datafeedUrl;
  }

  @Input()
  set libraryPath(libraryPath: ChartingLibraryWidgetOptions['library_path']) {
    this._libraryPath = libraryPath || this._libraryPath;
  }

  @Input()
  set chartsStorageUrl(
    chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url']
  ) {
    this._chartsStorageUrl = chartsStorageUrl || this._chartsStorageUrl;
  }

  @Input()
  set chartsStorageApiVersion(
    chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version']
  ) {
    this._chartsStorageApiVersion =
      chartsStorageApiVersion || this._chartsStorageApiVersion;
  }

  @Input()
  set clientId(clientId: ChartingLibraryWidgetOptions['client_id']) {
    this._clientId = clientId || this._clientId;
  }

  @Input()
  set userId(userId: ChartingLibraryWidgetOptions['user_id']) {
    this._userId = userId || this._userId;
  }

  @Input()
  set fullscreen(fullscreen: ChartingLibraryWidgetOptions['fullscreen']) {
    this._fullscreen = fullscreen || this._fullscreen;
  }

  @Input()
  set autosize(autosize: ChartingLibraryWidgetOptions['autosize']) {
    this._autosize = autosize || this._autosize;
  }

  @Input()
  set containerId(containerId: ChartingLibraryWidgetOptions['container_id']) {
    this._containerId = containerId || this._containerId;
  }

  @Input() component: string;

  ngOnInit(): void {
    this.studies_overrides = {
      'volume.volume.color.0': '#FD7474',
      'volume.volume.color.1': '#21CE99',
      'volume.volume.transparency': 70,
      'volume.volume ma.color': '#58B7E6',
      'volume.volume ma.transparency': 1000,
      'volume.volume ma.linewidth': 2,
      'volume.show ma': false,
    };

    this.custom_overrides = {
      volumePaneSize: 'tiny',
      'scalesProperties.showLeftScale': false,
      'scalesProperties.showRightScale': true,
      'mainSeriesProperties.areaStyle.color1': 'rgba(88,183,230, 0.2)',
      'mainSeriesProperties.areaStyle.color2': 'rgba(88,183,230, 0.2)',
      'mainSeriesProperties.areaStyle.linecolor': '#58B7E6',
      'mainSeriesProperties.areaStyle.linewidth': 2,
      'mainSeriesProperties.areaStyle.transparency': 100,
      'mainSeriesProperties.candleStyle.upColor': '#089981',
      'mainSeriesProperties.candleStyle.downColor': '#f43444',
      'mainSeriesProperties.candleStyle.drawWick': true,
      'mainSeriesProperties.candleStyle.drawBorder': true,
      'mainSeriesProperties.candleStyle.borderUpColor': '#089981',
      'mainSeriesProperties.candleStyle.borderDownColor': '#f43444',
      'mainSeriesProperties.candleStyle.wickUpColor': '#089981',
      'mainSeriesProperties.candleStyle.wickDownColor': '#f43444',
      'mainSeriesProperties.candleStyle.barColorsOnPrevClose': false,
      'mainSeriesProperties.barStyle.upColor': '#21CE99',
      'mainSeriesProperties.barStyle.downColor': '#FD7474',
      'mainSeriesProperties.barStyle.barColorsOnPrevClose': false,
      'mainSeriesProperties.barStyle.dontDrawOpen': false,
      'mainSeriesProperties.lineStyle.color': '#58B7E6',
      'mainSeriesProperties.lineStyle.linewidth': 2,
    };

    this.createWidget();
    this.getScreenSize();
  }

  ngOnChanges(changes: SimpleChanges) {
    this._symbol = changes.symbol.currentValue;
    this.createWidget();
  }

  ngAfterViewInit(): void {
    const chart = LightweightCharts.createChart(this.el.nativeElement, {
      width: 600,
      height: 300,
    });
    // Add your chart customization and data here
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    if (window.innerWidth < 500) {
      this.myInnerHeight = window.innerHeight - 100;
      this.custom_overrides['scalesProperties.showRightScale'] = false;
      this.createWidget();
    } else {
      this.myInnerHeight = window.innerHeight - 100;
      this.custom_overrides['scalesProperties.showRightScale'] = true;
      this.createWidget();
    }
  }

  getLanguageFromURL() {
    const regex = new RegExp('[\\?&]lang=([^&#]*)');
    const results = regex.exec(location.search);

    return results === null
      ? null
      : (decodeURIComponent(results[1].replace(/\+/g, ' ')) as LanguageCode);
  }

  createWidget() {
    let alt_symbol: string;
    // if (this.component == 'market') {
    //   alt_symbol = this._symbol.split('/')[0] + '/AUD';
    // } else if (this.component == 'trading-desk') {
    //   alt_symbol = this._symbol;
    // }
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: this._symbol,
      datafeed: Datafeed,
      interval: this._interval,
      container_id: this._containerId,
      library_path: this._libraryPath,
      locale: this.getLanguageFromURL() || 'en',
      disabled_features: [
        'compare_symbol',
        'header_compare',
        'use_localstorage_for_settings',
        'volume_force_overlay',
        'header_symbol_search',
        'header_settings',
        'CompareOrAddSymbol',
        'symbol_search_hot_key',
        'widget_logo',
        'link_to_tradingview',
        'create_volume_indicator_by_default',
        'header_interval_dialog_button',
        'study_templates',
        'chart_property_page_trading',
        'chart_crosshair_menu',
        'hide_last_na_study_output',
      ],
      overrides: this.custom_overrides,
      studies_overrides: this.studies_overrides,
      charts_storage_url: this._chartsStorageUrl,
      charts_storage_api_version: this._chartsStorageApiVersion,
      client_id: this._clientId,
      user_id: this._userId,
      fullscreen: this._fullscreen,
      autosize: this._autosize,
      toolbar_bg: '#f4f7f9',
      timezone: 'Australia/Sydney',
      drawings_access: { type: 'black', tools: [{ name: 'Trend Line' }] },
      debug: false,
      favorites: {
        intervals: ['60', 'D'],
        chartTypes: ['Candles', 'Line'],
      },
    };
    const tvWidget = new widget(widgetOptions);
    this._tvWidget = tvWidget;
  }

  ngOnDestroy() {
    if (this._tvWidget !== null) {
      this._tvWidget.remove();
      this._tvWidget = null;
    }
  }
}
