import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'table-loader',
  templateUrl: './table-loader.component.html',
  styleUrls: ['./table-loader.component.scss']
})
export class TableLoaderComponent implements OnInit {
  @Input() rowData:boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
